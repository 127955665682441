import { Set_Cart_list, Remove_From_Cart, Empty_Cart } from "./constant";

export const cartData = (data = [], action) => {
  switch (action.type) {
    case Set_Cart_list:
   
      return [...action.data];

    case Remove_From_Cart:

      const remainingItem = data.filter((item) => item.id !== action.data)
      return [...remainingItem];

    case Empty_Cart:

      data = [];
      return [...data];

    default:
      return data;
  }
};
