import axios from 'axios'
import React, { useState, useEffect } from 'react'
import { MdOutlineReviews } from 'react-icons/md'
import LoadingBar from "react-top-loading-bar";
import { toast } from "react-custom-alert";

import ReactStars from 'react-stars'
const RateUs = () => {

    const [isListOper, setIsListOper] = useState(false)

    const [rating, setRating] = useState(null)
    const [review, setReview] = useState("")
    const [progress, setProgress] = useState(0);

    const [allReview, setallReview] = useState([])


    useEffect(() => {


        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        });


        const userAllReview = async () => {

            try {
                await axios(
                    process.env.REACT_APP_ALL_REVIEWS_API,
                    {
                        method: "GET",
                    }
                ).then((res) => {
                    setProgress(75);
                    //toast.success(res.data.message);
                    setallReview(res.data.rating)

                    setProgress(100);

                }).catch((error) => {
                    if (error.response) {

                        toast.warning(error.response.data.message)

                        setProgress(100)
                    } else if (error.request) {

                        toast.error(error.request)
                        setProgress(100)
                    } else {

                        toast.error(error.message)
                        setProgress(100)
                    }
                });

            } catch (err) {
                toast.error('Something Wrong')
            }
        }
        userAllReview()

    }, [])

    const ratingChanged = (newRating) => {

        setRating(newRating)
    }




    const userReview = async () => {

        try {
            await axios(
                process.env.REACT_APP_REVIEWS_API,
                {
                    method: "POST",
                    headers: {
                        Authorization: `Bearer ${JSON.parse(localStorage.getItem('test_token'))}`,

                        "Content-Type": "application/json",
                    },

                    data: {
                        rating: rating,
                        review: review,
                    }
                }
            ).then((res) => {
                setProgress(75);
                toast.success(res.data.message);

                setProgress(100);
                setIsListOper(!isListOper)
                window.location.reload(false);
            }).catch((error) => {
                if (error.response) { // status code out of the range of 2xx

                    toast.warning(error.response.data.message)

                    setProgress(100)
                } else if (error.request) { // The request was made but no response was received

                    toast.error(error.request)
                    setProgress(100)
                } else {// Error on setting up the request

                    toast.error(error.message)
                    setProgress(100)
                }
            });

        } catch (err) {
            toast.error('Something Wrong')
        }

    }

    const namesArray = allReview.map(person => person.rating);




    const calculateAverage = (arr) => {
        const sum = arr.reduce((acc, num) => acc + num, 0);
        const average = sum / arr.length;
        return average;
    };

    const averageValue = calculateAverage(namesArray);


    return (
        <div>
            <LoadingBar
                color="#FF8000"
                progress={progress}
                onLoaderFinished={() => setProgress(0)}
            />
            <section>

                <div className="min-h-screen mx-auto max-w-screen-xl px-4 py-8 sm:px-6 lg:px-8">
                    <h2 className="text-xl font-bold sm:text-2xl inline-flex">Customer Reviews</h2>
                    <button onClick={() => setIsListOper(!isListOper)} className=" ml-3 shadow-md bg-green-500 hover:bg-amber-600 hover:shadow-lg font-bold py-2 px-4 rounded inline-flex items-center text-white">
                        <MdOutlineReviews size={20} />
                        <span className='ml-2 text-white'>Write a review</span>
                    </button>

                    {isListOper && (
                        <div
                            className="relative z-10"
                            aria-labelledby="modal-title"
                            role="dialog"
                        >
                            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

                            <div className="fixed inset-0 z-10 overflow-y-auto">
                                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                                    <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                                        <div className="px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                            <div className=" sm:items-start">
                                                <div className="mt-6">

                                                    <div className="mb-6">
                                                        <textarea
                                                            onChange={(e) => setReview(e.target.value)}
                                                            rows="10"
                                                            name="message"
                                                            placeholder="Your Message"
                                                            className="text-body-color bg-white rounded border-gray-300 focus:border-blue-500 focus:ring-2 focus:ring-indigo-200 border-[f0f0f0] focus:border-primary w-full border py-3 px-[14px] text-base outline-none focus-visible:shadow-none"
                                                        ></textarea>
                                                    </div>
                                                    <div className=" ml-2">
                                                        <p>Rate us</p>
                                                        <ReactStars
                                                            count={5}
                                                            onChange={ratingChanged}
                                                            size={30}
                                                            edit={true}
                                                            half={false}
                                                            color2={'#facc15'}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="bg-gray-50 px-4 lg:w-fill py-3 sm:flex sm:flex-row-reverse sm:px-6">
                                            <button
                                                onClick={() => setIsListOper(!isListOper)}
                                                type="button"
                                                className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                                            >
                                                Cancel
                                            </button>
                                            <button
                                                disabled={!rating || !review}
                                                onClick={userReview}
                                                type="button"
                                                className="inline-flex w-full justify-center rounded-md border border-transparent bg-green-500 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-400 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                                            >
                                                Submit
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    )}
                    <div className="mt-4 flex items-center gap-4">
                        <p className="text-3xl font-medium">
                            {averageValue.toFixed(1)}
                            <span className="sr-only"> Average review score </span>
                        </p>
                        <div>
                            <div className="flex">
                                <ReactStars
                                    count={5}
                                    value={parseFloat(averageValue)}
                                    edit={false}
                                    size={25}
                                    color2={'#facc15'}
                                />
                            </div>

                            <p className="mt-0.5 text-xs text-gray-500">{`Based on ${allReview.length} reviews`}</p>
                        </div>
                    </div>
                    <div className="mt-8 grid grid-cols-1 gap-x-16 gap-y-12 lg:grid-cols-2">
                        {allReview.map((reviewData) =>
                            <blockquote key={reviewData.id}>
                                <header className="sm:flex sm:items-center sm:gap-4">
                                    <div className="flex">
                                        <div className="">
                                            <ReactStars
                                                count={5}
                                                value={parseFloat(reviewData.rating)} // Parse the string to a number
                                                edit={false}
                                                size={25}
                                                color2={'#facc15'}
                                            />

                                        </div>
                                    </div>

                                    {/* <p className="mt-2 font-medium sm:mt-0">{reviewData.rating}</p> */}
                                </header>

                                <p className="mt-2 text-gray-700">
                                    {reviewData.review}
                                </p>
                                <footer className="mt-4">
                                    <p className="text-xs text-gray-500">{reviewData.name} - {reviewData.date}</p>
                                </footer>
                            </blockquote>
                        )}
                    </div>
                </div>
            </section>
        </div>
    )
}

export default RateUs
