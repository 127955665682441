import React, { useEffect, useState } from "react";
import { AiFillStar } from "react-icons/ai"
// import { AiOutlineShoppingCart } from 'react-icons/ai'
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom'
import LoadingBar from 'react-top-loading-bar'
import axios from "axios";
import { toast } from "react-custom-alert";
import { useDispatch } from "react-redux";
// import { addToCart } from "../../redux/action";
// import { useSelector } from "react-redux";
// import { FaTimes } from "react-icons/fa";
import {
  product
} from "../../redux/productAction";
const Wishlist = () => {

  const navigat = useNavigate()

  const [progress, setProgress] = useState(0)

  const [listItem, setlistItem] = useState([])

  // const result = useSelector((state) => state.cartData);

  const dispatch = useDispatch();

  let imgUrl = "https://kaeinyonne.com/admin/product_image/"

  useEffect(() => {
    if (!localStorage.getItem('test_token')) {
      navigat('/login')
    }

    const wishlist = async () => {

      try {
        await axios(
          process.env.REACT_APP_API_WISHLIST_API,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${JSON.parse(localStorage.getItem('test_token'))}`,

              "Content-Type": "application/json",
            },
          }
        ).then((res) => {
          setProgress(75);
          setlistItem(res.data.wishlist)
          // console.log(res.data.wishlist[1].product_details[1].id)
          setProgress(100);
        }).catch((error) => {
          if (error.response) { // status code out of the range of 2xx
     
            toast.warning(" Please login")
      
            setProgress(100)
          } else if (error.request) { // The request was made but no response was received
         
            toast.error(error.request)
            setProgress(100)
          } else {// Error on setting up the request
         
            toast.error(error.message)
            setProgress(100)
          }
        });

      } catch (err) {
        toast.error('Something Wrong')
      }

    }

    wishlist()

  }, [navigat])

  // let product = listItem.map(item => item.product_details.map(sellarid=> sellarid.seller_id))

  // console.log(product)

  const removeItem = async (e) => {
 
    try {
      await axios(
        process.env.REACT_APP_API_WISHLIST_DELETE_API,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${JSON.parse(localStorage.getItem('test_token'))}`,

            "Content-Type": "application/json",
          },
          data: {
            product_id: e.currentTarget.id
          }
        }
      ).then((res) => {
        //navigat('/products')
        setProgress(75);
        window.location.reload(false);
        setlistItem(res.data.wishlist)
     
        setProgress(100);
      }).catch((error) => {
        if (error.response) { // status code out of the range of 2xx
        
          toast.warning(error.response.data.message)
        
          setProgress(100)
        } else if (error.request) { // The request was made but no response was received
        
          toast.error(error.request)
          setProgress(100)
        } else {// Error on setting up the request
        
          toast.error(error.message)
          setProgress(100)
        }
      });

    } catch (err) {
      toast.error('Something Wrong')
    }
  }


  // // add to cart
  // const handleCart = async (e) => {
   
  //   // let array = e.seller_id.toString().split("");
  //   // console.log(array);

  //   try {
  //     await axios(
  //       process.env.REACT_APP_API_ADD_TO_CART,
  //       {
  //         method: "POST",
  //         headers: {
  //           Authorization: `Bearer ${JSON.parse(
  //             localStorage.getItem("test_token")
  //           )}`,

  //           "Content-Type": "application/json",
  //         },
  //         data: {
  //           product_id: e.id,
  //           quantity: 1,
  //           price: e.sale_price_gst,
  //           discount: e.product_discount,
  //           name: e.product_name,
  //           shipping_cost: 0,
  //           seller_id: e.seller_id
  //         },
  //       }
  //     )
  //       .then((res) => {
  //         setProgress(75);
  //         toast.success(res.data.message);
  //         dispatch(addToCart(e));
        
  //         setProgress(100);
  //       })
  //       .catch((error) => {
  //         if (error.response) {
           
  //           toast.warning(error.response.data.message);
     
  //           setProgress(100);
  //         } else if (error.request) {
          
  //           toast.error(error.request);
  //           setProgress(100);
  //         } else {

  //           toast.error(error.message);
  //           setProgress(100);
  //         }
  //       });
  //   } catch (err) {
  //     toast.error("Something Wrong");
  //   }
  // };

  return (
    <section className="py-20 min-h-screen">
      <LoadingBar
        color="#FF8000"
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
      />
      <h1 className="mb-12 text-center text-5xl font-fontHading text-gray-900">
        Wish List
      </h1>
      <div className="mx-auto  grid max-w-screen-xl grid-cols-1 gap-6 p-6 md:grid-cols-2 lg:grid-cols-3">
        {listItem.map(item => item.product_details.map((productData) =>
          <article key={item.id} className="rounded-xl bg-white p-3 shadow-lg hover:shadow-xl">
            <div >
              <div className="relative flex items-end overflow-hidden justify-center">
                <Link
                  onClick={() => dispatch(product(productData.id))}
                  to={`/product/${productData.id}`}
                >
                  <img src={`${imgUrl}${productData.product_image}`} alt="" className="rounded-xl object-fill object-center h-52 w-auto" />
                </Link>
                <div className="absolute bottom-3 left-3 inline-flex items-center rounded-full bg-white p-2 shadow-md">
                  <samp className="text-yellow-400"><AiFillStar /></samp>
                  <span className="text-slate-400 ml-1 text-xs">4.9</span>
                </div>
                {/* <div className="absolute top-3 right-3 inline-flex items-center rounded-full bg-white p-2 shadow-md">
                  <samp className=" text-red-400"><FaTimes /></samp>
                </div> */}
              </div>
              <div className="mt-1 p-1">
                <h2 className="text-slate-700">{productData.product_name}</h2>
                <p className="text-slate-400 mt-1 text-sm">{productData.small_description}</p>

                <div className="mt-2 flex items-end justify-between">
                  <div>
                    <p className="space-x-2">
                      <span className="lg:text-sm md:text-xs text font-semibold">₹{productData.sale_price_gst}</span>
                      <span className="text-sm line-through text-gray-500">
                        ₹{productData.product_price}
                      </span>
                      <span className="text-xs text-red-700">{(100 * (productData.product_price - productData.sale_price) / productData.product_price).toFixed()}% off</span>
                    </p>
                  </div>
                  <div className="flex justify-center mt-3">
                    {/* <button
                      disabled={
                        result.find(
                          (cartItem) =>
                            cartItem.id === productData.id
                        )
                          ? true
                          : false
                      }
                      onClick={(e) => handleCart(productData)}
                      className={`px-2 py-1 ${result.find(
                        (cartItem) =>
                          cartItem.id === productData.id
                      )
                        ? "bg-gray-600"
                        : "bg-[#88C645] hover:bg-[#F79946]"}  text-center text-sm text-white rounded duration-300`}>
                      <AiOutlineShoppingCart />
                    </button> */}
                    <button
                      id={productData.id}
                      onClick={removeItem}
                      className="lg:px-2 md:px-1 py-1 ml-2 bg-red-400 hover:bg-red-500 text-center text-sm text-white rounded duration-300">
                      Remove
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </article>
        ))}
      </div>
    </section>
  );
};

export default Wishlist;
