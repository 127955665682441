import React from "react";
import { TbTruckDelivery } from "react-icons/tb"
import { MdOutlineChatBubbleOutline } from "react-icons/md"
import { RiSecurePaymentFill } from "react-icons/ri"
import { TbReplace } from "react-icons/tb"
// import axios from 'axios'
// import { toast } from 'react-custom-alert';
const Highlight = () => {

  // const [data, setData] = useState([])
  // useEffect(() => {
  //   const contactData = async () => {

  //     try {

  //       await axios.get(process.env.REACT_APP_API_CONTACT_API,
  //       ).then((res) => {

  //         setData(res.data.list)

  //         //navigat('/')
  //       }).catch((error) => {
  //         if (error.response) { // status code out of the range of 2xx
  //           console.log("Data :", error.response.data);
  //           //toast.warning(error.response.data.message)
  //           console.log("Status :" + error.response.status);

  //         } else if (error.request) { // The request was made but no response was received
  //           console.log(error.request);
  //           toast.error(error.request)

  //         } else {// Error on setting up the request
  //           console.log('Error', error.message);
  //           toast.error(error.message)

  //         }
  //       })

  //     } catch (err) {
  //       toast.error('Something Wrong')
  //     }
  //   }


  //   contactData()
  // }, [])




  return (
    <div className="2xl:container 2xl:mx-auto md:py-3 py-9 ml-8">
      <div className=" grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 lg:gap-8 md:gap-12 gap-14 lg:px-20 lg:py-6 py-10 md:px-12 px-4">
        {/* Delivery grid Card */}
        <div className="flex gap-2 ">
          <TbTruckDelivery size={38} />
          <div>
            <h3 className=" text-xl leading-5 font-semibold text-gray-800 mt-2  ">
              Delivery
            </h3>
            <p className=" capitalize text-base leading-6 font-normal text-gray-600 mt-2 lg:w-full md:w-9/12 w-full">
              100% Fast And Secure Delivery All Over India
            </p>

          </div>

        </div>

        {/* customer Grid Card */}

        <div className="flex gap-2 ">
          <MdOutlineChatBubbleOutline size={26} />
          <div>
          <h3 className=" text-xl leading-5 font-semibold text-gray-800 ">
            Customer Care
          </h3>
          {/* {data.map((item)=> */}
          <p className=" text-base leading-6 font-normal text-gray-600 mt-4 lg:w-full md:w-9/12 w-full">
            <span className=" font-semibold cursor-pointer">Phone- 8617655237</span>
            <br />
            <span className=" font-semibold cursor-pointer">
              Email- help@kaeinyonne.com
            </span>
          </p>
          {/* )} */}

          </div>
        </div>

        {/* Recycle Grid Card */}

        <div className="flex gap-2 ">
          <TbReplace size={30} />
          <div>
          <h3 className=" text-xl leading-5 font-semibold text-gray-800 mt-1 ">
            Return
          </h3>
          <p className=" capitalize text-base leading-6 font-normal text-gray-600 mt-4 lg:w-full md:w-9/12 w-full">
            If you want to return your ordered products see our return policy
          </p>

          </div>
        </div>

        {/* Secure Payment Card */}

        <div className="flex gap-2 ">
          <RiSecurePaymentFill size={32} />
          <div>
          <h3 className=" text-xl leading-5 font-semibold text-gray-800 mt-1 ">
            Secure Payment
          </h3>
          <p className=" capitalize text-base leading-6 font-normal text-gray-600 mt-4 lg:w-full md:w-9/12 w-full">
            Transaction process has end to end encryption
          </p>


          </div>
        </div>
      </div>
    </div>
  );
};

export default Highlight;
