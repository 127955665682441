import axios from "axios";
import React, { useState, useEffect } from "react";
import { toast } from "react-custom-alert";
import { FcCheckmark } from 'react-icons/fc'
import LoadingBar from "react-top-loading-bar";
// import Img from '../../assets/logo2.png';
import { useNavigate } from 'react-router-dom'

const Service = () => {
  const navigat = useNavigate()

  const [serviceList, setserviceList] = useState([])
  const [progress, setProgress] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  const [userNameErr, setUserNameErr] = useState(false)
  const [phoneErr, setPhoneErr] = useState(false);
  const [emailErr, setemailErr] = useState(false)

  const [id, setid] = useState()
  const [ammount, setAmmount] = useState()
  const [name, setName] = useState()
  const [email, setEmail] = useState()
  const [phone, setPhone] = useState()

  const [dateLeft, setDateLeft] = useState()

  useEffect(() => {

    if (!localStorage.getItem('test_token')) {
      navigat('/login')
    }

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth"
    });


    //serviceList
    setProgress(45)
    const serviceList = async () => {

      try {
        setProgress(75)
        await axios(process.env.REACT_APP_API_SERVICE_LIST, {
          method: 'get'
        }).then((res) => {

          setserviceList(res.data.product_list)
          setProgress(100)
        }).catch((error) => {
          if (error.response) { // status code out of the range of 2xx

            toast.warning(" Please login")

            setProgress(100)

          } else if (error.request) { // The request was made but no response was received

            toast.error(error.request)
            setProgress(100)
          } else {// Error on setting up the request

            toast.error(error.message)
            setProgress(100)
          }
        })

      } catch (err) {
        toast.error('Something Wrong')
      }

    }

    const handelServiceCheck = async () => {
      setProgress(75);
      try {
        await axios(
          process.env.REACT_APP_API_SERVICE_CHECK_API,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${JSON.parse(localStorage.getItem('test_token'))}`,

              "Content-Type": "application/json",
            },
          }
        ).then((res) => {
          //setProgress(75);
          //setCheckPremiumService(res.data);

          setDateLeft(res.data)
          setProgress(100);
        }).catch((error) => {
          if (error.response) { // status code out of the range of 2xx

            toast.warning(" Please login")

            setProgress(100)
          } else if (error.request) { // The request was made but no response was received

            toast.error(error.request)
            setProgress(100)
          } else {// Error on setting up the request

            toast.error(error.message)
            setProgress(100)
          }
        });

      } catch (err) {
        toast.error('Something Wrong')
      }
    }

    handelServiceCheck()
    serviceList()
  }, [navigat])

  const handelService = async (e) => {

    setAmmount(e.currentTarget.value);
    setid(e.currentTarget.id);


    if (dateLeft.avaliable === "no") {
      setIsOpen(!isOpen);
    } else {
      toast.warning(dateLeft.message + " " + dateLeft.left_days + " Left");
    }
  };

  //userName_Vault
  const userNameHandler = (e) => {
    let item = e.target.value;
    const isValidName = /^[a-zA-Z]+ [a-zA-Z]/;

    if (isValidName.test(item)) {
      setUserNameErr(false)
    } else {
      setUserNameErr(true)
    }
    setName(item)


  }

  //userEmail_Vault
  const emailHandler = (e) => {
    let item = e.target.value;
    const isValidEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

    if (isValidEmail.test(item)) {
      setemailErr(false)
    } else {
      setemailErr(true)
    }
    setEmail(item)
  }

  //userPhone_Vault
  const phoneHandler = (e) => {
    let item = e.target.value;
    if (item.length < 10) {
      setPhoneErr(true)
    }
    else {
      setPhoneErr(false)
    }
    setPhone(item)
  }



  //payments function razorpay
  const SubmitRazorpay = () => {
    setIsOpen(!isOpen)

    // console.log(id, ammount)

    try {
      setProgress(75)
      axios(
        process.env.REACT_APP_API_SERVICE_PAY_API,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${JSON.parse(localStorage.getItem('test_token'))}`,

            "Content-Type": "application/json",
          },
          data: {
            premium_service_id: id,
            //order_id: response.razorpay_order_id,
            //transaction_id: response.razorpay_payment_id,
            amount: ammount
          }
        }
      ).then((res) => {
        setProgress(100)
        // console.log("response from api is ", res.data);
        const url = res.data.key;
        // console.log("url", url);
        // console.log("URL to Open:", url);
        window.open(url, "_blank");
        //window.location.reload(false);
      }).catch((error) => {
        if (error.response) { // status code out of the range of 2xx

          toast.warning(" Please login")

          setProgress(100)
        } else if (error.request) { // The request was made but no response was received

          toast.error(error.request)
          setProgress(100)
        } else {// Error on setting up the request

          toast.error(error.message)
          setProgress(100)
        }
      });

    } catch (err) {
      toast.error('Something Wrong')
    }


  }

  // console.log("rrrrrrrrrrrrrrrr",serviceList)


  return (
    <div>
      <LoadingBar
        color="#FF8000"
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
      />

      <div className="pt-16 pb-8 text-center leading-8 text-gray-800 md:pb-10 lg:pt-32 min-h-screen ">
        <div className="mb-20 text-center">
          <div className="mb-4 text-gray-800">
            <h2 className="text-4xl font-bold md:text-5xl md:leading-none">
              Premium Services
            </h2>
          </div>
          <p className="mx-auto mb-8 max-w-3xl text-gray-800">
            Join our premium service for enjoying your extra facility.
          </p>
        </div>
        <div className="mx-auto grid max-w-5xl grid-cols-1 gap-5 text-center md:grid-cols-4">
          {serviceList.map((service) =>
            <div key={service.id} className="relative text-gray-800">
              <div className={`relative mx-auto flex max-w-sm flex-col overflow-hidden rounded-md border ${service.id === 2 ? 'border-orange-100' : 'border-purple-100'}  `}>
                <div className={`${service.id === 2 ? 'bg-orange-50' : 'bg-purple-50'} py-2 text-xl`}>{service.mode} Months</div>
                <div className="py-10 px-4 font-semibold">
                  <p className="  ">

                    <span className="text-base leading-tight">₹</span>{service.amount} / {service.mode} Months
                  </p>
                </div>
                {<p className="mx-auto h-24 max-w-xs px-6 text-xl">
                  {service.facilities.split(".")[1]}
                </p>}

                <ul className="  ">
                  <li className="relative mx-4 mb-2rounded-md bg-gray-50">
                    <FcCheckmark className="absolute block top-1" size={18} />
                    {<p className="text-xl ml-2 font-semibold">
                      {service.facilities.split(".")[3]}
                    </p>}
                  </li>
                  <li className="relative mx-4 mb-2 rounded-md bg-gray-50">
                    <p className="py-2 text-xl font-semibold">{service.facilities.split(".")[5]}</p>
                  </li>
                </ul>
                <div className="my-10 px-2">
                  <button id={service.id} value={service.amount} onClick={handelService}
                    className={`rounded ${service.id === 2 ? 'bg-orange-600' : 'bg-gray-800'} py-4 px-10 text-base leading-tight text-white duration-200 ease-in-out md:inline-block lg:py-4`}
                  >
                    Get Started
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {isOpen && (
        <div
          className="relative z-10"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <div className="relative mt-4 transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <div className="">
                  <div className=" sm:items-start">

                    <div className="bg-white rounded shadow-lg p-4 px-4 md:p-8">
                      <div className="grid gap-4 gap-y-2 text-sm grid-cols-1 lg:grid-cols-3">
                        <div className="text-gray-600">
                          <p className="font-medium text-lg">Personal Details</p>
                          <p>Please fill out all the fields.</p>
                        </div>

                        <div className="lg:col-span-2">
                          <div className="grid gap-4 gap-y-2 text-sm grid-cols-1 md:grid-cols-5">
                            <div className="md:col-span-5">
                              {/* //<label htmlFor="full_name">Full Name</label> */}
                              <input onChange={userNameHandler} type="text" placeholder="Full Name" name="full_name" id="full_name" className="h-10 border mt-1 rounded px-4 w-full bg-gray-50" />
                              {userNameErr ? <label className=" text-red-500 ml-3">Enter a valid name</label> : ""}
                            </div>

                            <div className="md:col-span-5">

                              <input onChange={phoneHandler} type="number" name="phone" className="h-10 border mt-1 rounded px-4 w-full bg-gray-50" placeholder="Mobile Number" />
                              {phoneErr ? <label className=" text-red-500 ml-3">Enter a valid number</label> : ""}
                            </div>
                            <div className="md:col-span-5">

                              <input onChange={emailHandler} type="email" name="email" className="h-10 border mt-1 rounded px-4 w-full bg-gray-50" placeholder="Email Address" />
                              {emailErr ? <label className=" text-red-500 ml-3">Enter a valid email</label> : ""}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 lg:w-fill py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  <button
                    onClick={() => setIsOpen(!isOpen)}
                    type="button"
                    className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                  >
                    Cancel
                  </button>
                  <button
                    disabled={!phone || !email || !name}
                    onClick={SubmitRazorpay}
                    type="button"
                    className={`inline-flex w-full justify-center ${!phone || !email || !name ? 'cursor-no-drop bg-gray-500 hover:bg-gray-700' : 'cursor-pointer bg-green-500 hover:bg-green-700'} rounded-md border border-transparent  px-4 py-2 text-base font-medium text-white shadow-sm ocus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm`}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Service;
