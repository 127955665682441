import React, { useState, useEffect } from 'react'
import axios from "axios";
import LoadingBar from "react-top-loading-bar";
import { toast } from "react-custom-alert";
import { BsArrowRightShort } from 'react-icons/bs'
import { BsArrowLeftShort } from 'react-icons/bs'
import UserIcon from '../../assets/usericon.png'

const Transactionhistory = () => {


    const [faqData, setfaqData] = useState([])
    const [progress, setProgress] = useState(0);
    const [userInfo, setUserInfo] = useState([]);

    const [page, setPage] = useState(1);

    let url = "https://kaeinyonne.com/admin/profile_image/"

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        });


        const faq = async () => {
            try {
                await axios(process.env.REACT_APP_API_TRANSACTION_HISTORY, {
                    method: "POST",
                    headers: {
                        Authorization: `Bearer ${JSON.parse(
                            localStorage.getItem("test_token")
                        )}`,

                        "Content-Type": "application/json",
                    },
                })
                    .then((res) => {
                        setProgress(75);
                        setfaqData(res.data.list);
                      
                        setProgress(100);
                    })
                    .catch((error) => {
                        if (error.response) {
                    
                            toast.warning(error.response.data.message);
                        
                            setProgress(100);
                        } else if (error.request) {
                        
                            toast.error(error.request);
                            setProgress(100);
                        } else {
                        
                            toast.error(error.message);
                            setProgress(100);
                        }
                    });
            } catch (err) {
                toast.error("Something Wrong");
            }
        };

        faq()

    }, [])

    useEffect(() => {
        const userData = async () => {
            try {
                await axios(process.env.REACT_APP_API_USER_DETAILS, {
                    method: "POST",
                    headers: {
                        Authorization: `Bearer ${JSON.parse(
                            localStorage.getItem("test_token")
                        )}`,

                        "Content-Type": "application/json",
                    },
                })
                    .then((res) => {
                        setProgress(75);
                        setUserInfo(res.data.userDetails);
                       ;
                        setProgress(100);
                    })
                    .catch((error) => {
                        if (error.response) {
                           
                            toast.warning(" Please login");
                         
                            setProgress(100);
                        } else if (error.request) {
                          
                            toast.error(error.request);
                            setProgress(100);
                        } else {
                         
                            toast.error(error.message);
                            setProgress(100);
                        }
                    });
            } catch (err) {
                toast.error("Something Wrong");
            }
        };
        userData()
    }, [url])



    let lent = faqData.length;



    const selecPageHandler = (selectedPage) => {
        if (
            selectedPage >= 1 &&
            selectedPage <= Math.ceil(lent / 10) &&
            selectedPage !== page
        )
            setPage(selectedPage);
    };


    return (
        <section className="container px-4 mx-auto py-4">
            <LoadingBar
                color="#FF8000"
                progress={progress}
                onLoaderFinished={() => setProgress(0)}
            />
            <div className="flex items-center justify-between">
                <div>
                    <h2 className="text-lg font-medium text-gray-800 ">
                        Transaction history
                    </h2>
                    {/* <p className="mt-1 text-sm text-gray-500 ">
                        This is a list of all employees. You can add new employees, edit or
                        delete existing ones.
                    </p> */}
                </div>
            </div>
            <div className="flex flex-col mt-6 overflow-auto md:overflow-hidden">
                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                        <div
                            className="overflow-hidden border border-gray-200  md:rounded-lg">
                            <table
                                className="min-w-full divide-y divide-gray-200 ">
                                <thead className="bg-gray-50 ">
                                    <tr className="divide-x divide-gray-200">
                                        <th
                                            scope="col"
                                            className="py-3.5 px-4 text-sm font-normal text-center rtl:text-right text-gray-500 ">
                                            <span>User</span>
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-12 py-3.5 text-sm font-normal text-center rtl:text-right text-gray-500 ">
                                            Amount
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-4 py-3.5 text-sm font-normal text-center rtl:text-right text-gray-500 ">
                                            Purpose
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-4 py-3.5 text-sm font-normal text-center rtl:text-right text-gray-500 ">
                                            Date
                                        </th>

                                    </tr>
                                </thead>
                                <tbody
                                    className="bg-white divide-y divide-gray-200  ">
                                    {faqData.slice(page * 10 - 10, page * 10).map((item) =>
                                        <tr key={item.id} className="divide-x divide-gray-200">
                                            <td className="py-4 px-4 whitespace-nowrap">
                                                <div className="flex items-center">
                                                    <div className="flex-shrink-0 h-10 w-10">
                                                        <img
                                                            className="h-10 w-10 rounded-full object-cover"
                                                            src={url + userInfo.profile_image}
                                                            alt=""
                                                            loading="lazy"
                                                            onError={(event) => event.target.src = UserIcon} />
                                                    </div>
                                                    <div className="ml-4">
                                                        <div
                                                            className="text-sm font-medium text-gray-900 ">
                                                            {userInfo.name}
                                                        </div>
                                                        <div className="text-sm text-gray-500 ">
                                                            {userInfo.email}
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>

                                            <td className="px-12 py-4 whitespace-nowrap">
                                                <div className="text-sm text-gray-900 ">
                                                    ₹ {item.amount} {item.status}
                                                </div>
                                                {/* <div className="text-sm text-gray-500 ">
                                                    
                                                </div> */}
                                            </td>
                                            <td className="px-4 py-4 whitespace-nowrap">
                                                <span
                                                    className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                                    {item.purpose}
                                                </span>
                                            </td>
                                            <td
                                                className="px-4 py-4 whitespace-nowrap text-sm text-gray-500 ">
                                                {item.date}
                                            </td>

                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            {lent > 0 && (
                <div className="flex justify-center items-center mb-5 mt-4">
                    <span
                        onClick={() => selecPageHandler(page - 1)}
                        className={`flex items-center px-2 py-2 mx-1 border cursor-pointer ${page > 1 ? "" : "opacity-0"
                            } border-gray-800 rounded-full text-gray-900 hover:scale-105 hover:bg-[#f79946b0]`}
                    >
                        <BsArrowLeftShort size={24} />
                    </span>

                    {[...Array(Math.ceil(lent / 10))].map((_, i) => {
                        return (
                            <span
                                onClick={() => selecPageHandler(i + 1)}
                                key={i}
                                className={`flex  listHid items-center px-4 py-2 mx-1 border cursor-pointer border-gray-800 rounded-full ${page === i + 1 ? " bg-[#f79946b0]" : ""
                                    }  text-gray-900 hover:scale-105`}
                            >
                                {i + 1}
                            </span>
                        );
                    })}

                    <span
                        onClick={() => selecPageHandler(page + 1)}
                        className={`flex items-center px-2 py-2 mx-1 border cursor-pointer ${page < Math.ceil(lent / 10) ? "" : "opacity-0"
                            } border-gray-800 rounded-full text-gray-900 hover:scale-105 hover:bg-[#f79946b0]`}
                    >
                        <BsArrowRightShort size={24} />
                    </span>
                </div>
            )}

        </section>

    )
}

export default Transactionhistory
