import React, { useState, useEffect, useRef } from "react";
import { IoMdNotificationsOutline, IoMdClose } from "react-icons/io";
import axios from "axios";

const NotificationDropdown = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [notifications, setNotifications] = useState([]);
    const [notificationCount, setNotificationCount] = useState(0);
    const token = JSON.parse(localStorage.getItem("test_token"));
    const [imgUrl, setImageUrl] = useState(null);
    const dropdownRef = useRef(null);
    const buttonRef = useRef(null);
    const panelRef = useRef(null); // New reference for the panel

    useEffect(() => {
        const fetchNotifications = async () => {
            try {
                if (!token) throw new Error("No token found");

                const headers = { Authorization: `Bearer ${token}` };

                // Fetch notification count
                const countResponse = await axios.get('https://kaeinyonne.com/admin/api/activitynotification-count', { headers });
                console.log(countResponse, "countResponse");

                // Fetch notification list
                const notificationsResponse = await axios.post(
                    'https://kaeinyonne.com/admin/api/activity-notification-list',
                    {},
                    { headers }
                );
                console.log(notificationsResponse.data, "notification list");

                // Set the base URLs for images
                setImageUrl(notificationsResponse?.data?.baseUrl);

                setNotifications(notificationsResponse.data?.notification_list || []);
                setNotificationCount(notificationsResponse.data?.notification_list?.length || 0);
            } catch (error) {
                console.error("Error fetching notifications:", error);
            }
        };

        fetchNotifications(); // Initial fetch

        const intervalId = setInterval(() => {
            fetchNotifications(); // Refetch every 2 seconds
        }, 2000);

        return () => clearInterval(intervalId); // Clear interval on component unmount
    }, [token]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                panelRef.current && !panelRef.current.contains(event.target) &&
                buttonRef.current && !buttonRef.current.contains(event.target)
            ) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const toggleDropdown = () => {
        setIsOpen(prevState => !prevState);
    };

    const deleteNotification = async (notificationId) => {
        try {
            const headers = {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            };

            // Send POST request to delete the notification
            const deleteNotificationResponse = await axios.post(
                'https://kaeinyonne.com/admin/api/activity-notification-delete',
                { notification_id: notificationId }, // Send notificationId in the request body
                { headers }
            );
            console.log(deleteNotificationResponse, "deleteNotification");

            // Update notifications after successful deletion
            setNotifications(notifications.filter(notification => notification.id !== notificationId));
            setNotificationCount(prevCount => prevCount - 1);
        } catch (error) {
            console.error("Error deleting notification:", error.response ? error.response.data : error.message);
        }
    };

    return (
        <div className="relative inline-block text-left">
            <button
                onClick={toggleDropdown}
                ref={buttonRef}
                className="ml-1 lg:ml-4 relative inline-block"
            >
                <div className="hover:text-orange-400">
                    <IoMdNotificationsOutline className="w-5 h-5 sm:w-6 sm:h-6" />
                </div>

                {notificationCount > 0 && (
                    <span className="absolute -top-2 -left-2 md:-top-2 md:-left-1 lg:-top-1 lg:-left-1 bg-[#88C645] text-xs font-bold px-1.5 py-0.5 rounded-full">
                        {notificationCount}
                    </span>
                )}
            </button>

            {isOpen && (
                <div
                    className="relative z-20"
                    aria-labelledby="slide-over-title"
                    role="dialog"
                    aria-modal="true"
                    // Attach the ref to the panel
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity h-screen"></div>

                    <div className="fixed inset-0 overflow-hidden">
                        <div className="absolute inset-0 overflow-hidden">
                            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                                <div className="pointer-events-auto w-screen h-screen max-w-md">
                                    <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                                        <div className="flex-1 overflow-y-auto py-6 px-4 sm:px-6"  ref={panelRef}>
                                            <div className="flex items-start justify-between">
                                                <h2
                                                    className="text-lg font-medium text-gray-900"
                                                    id="slide-over-title"
                                                >
                                                    Notification
                                                </h2>
                                                <div className="ml-3 flex h-7 items-center">
                                                    <button
                                                        type="button"
                                                        onClick={() => setIsOpen(!isOpen)}
                                                        className="-m-2 p-2 text-gray-400 hover:text-gray-500"
                                                    >
                                                        <span className="sr-only">Close panel</span>
                                                        <svg
                                                            className="h-6 w-6"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            fill="none"
                                                            viewBox="0 0 24 24"
                                                            strokeWidth="1.5"
                                                            stroke="currentColor"
                                                            aria-hidden="true"
                                                        >
                                                            <path
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                                d="M6 18L18 6M6 6l12 12"
                                                            />
                                                        </svg>
                                                    </button>
                                                </div>
                                            </div>

                                            <div className="mt-8">
                                                <div className="py-2 max-h-64 overflow-y-auto">
                                                    {notifications.length > 0 ? (
                                                        notifications.map((notification) => (
                                                            <div key={notification.id} className="relative px-4 py-2 text-sm text-gray-700">
                                                                <button
                                                                    onClick={() => deleteNotification(notification.id)}
                                                                    className="absolute top-2 right-2 text-gray-500 hover:text-red-500"
                                                                >
                                                                    <IoMdClose />
                                                                </button>
                                                                <div className="flex items-center space-x-3">
                                                                    <img
                                                                        src={`${imgUrl}${notification.product_imgae}`}
                                                                        alt={notification.product_name}
                                                                        className="w-12 h-12 object-cover rounded-md"
                                                                    />
                                                                    <div className="flex-1">
                                                                        <div className="text-sm font-medium text-gray-800">{notification.product_name}</div>
                                                                        <div className="text-xs text-gray-600">{notification.description}</div>
                                                                        <div className="mt-1 flex items-center space-x-2">
                                                                            <span className="font-bold text-gray-900">
                                                                                {notification.sale_price || notification.product_price}
                                                                            </span>
                                                                            {notification.sale_price && (
                                                                                <span className="text-xs text-gray-600 line-through">
                                                                                    {notification.product_price}
                                                                                </span>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))
                                                    ) : (
                                                        <div className="px-4 py-2 text-sm text-gray-700">
                                                            No new notifications.
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default NotificationDropdown;
