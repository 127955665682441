import React, { useEffect, useState } from "react";
import { FaUserAlt } from "react-icons/fa";
import { IoWalletSharp } from "react-icons/io5";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import LoadingBar from "react-top-loading-bar";
import { toast } from "react-custom-alert";
import { FaEdit } from "react-icons/fa";
import { HiUserGroup } from "react-icons/hi";
import { MdMiscellaneousServices } from "react-icons/md";
import { FiCopy } from "react-icons/fi";
// import { AiFillEye } from "react-icons/ai";
// import { AiFillEyeInvisible } from "react-icons/ai";
import { RxUpdate } from "react-icons/rx";
import UserIcon from '../../assets/usericon.png'
import coin from "../../assets/coin-icon.png"
import NotificationLink from "../notification/NotificationLink";
import NotificationList from "../notification/NotificationList";

const Userprofile = () => {
  const [userInfo, setUserInfo] = useState([]);

  const [progress, setProgress] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  // const [passOpen, setPassOpen] = useState();

  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [address, setAddress] = useState();
  const [dob, setDob] = useState();

  // const [newPass, setNewPass] = useState();
  // const [confirmPass, setConfirmPass] = useState();
  const [imgUrl, setImgUrl] = useState();

  const [checkPremiumService, setCheckPremiumService] = useState([]);

  // img uplod
  const [imgOpen, setImgOpen] = useState(false);
  const [imgUplod, setImgUplod] = useState("");

  const [isListOper, setIsListOper] = useState(false);

  const [wallet, setwallet] = useState("");

  const [serviceList, setserviceList] = useState([]);

  // const [changePassword, setChangePassword] = useState(true);
  // const changeIcon = changePassword === true ? false : true;



  const [dname, setdName] = useState();
  const [number, setnumber] = useState();
  const [daddress, setdAddress] = useState();
  const [city, setcity] = useState();
  const [pinCord, setpinCord] = useState();
  const [state, setstate] = useState();
  const [country, setcountry] = useState();
  const [type, settype] = useState("Home");

  const [checkout, setcheckout] = useState(false);

  const [raeData, setraeData] = useState([])

  const navigat = useNavigate();
  useEffect(() => {
    if (!localStorage.getItem("test_token")) {
      navigat("/login");
    }
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
  }, [navigat]);

  useEffect(() => {
    // userInfo auto update
    const userData = async () => {
      try {
        await axios(process.env.REACT_APP_API_USER_DETAILS, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${JSON.parse(
              localStorage.getItem("test_token")
            )}`,

            "Content-Type": "application/json",
          },
        })
          .then((res) => {
            setProgress(75);
            setUserInfo(res.data.userDetails);
            setImgUrl(res.data.baseUrl);

            setProgress(100);
          })
          .catch((error) => {
            if (error.response) {

              toast.warning(" Please login");

              setProgress(100);
            } else if (error.request) {

              toast.error(error.request);
              setProgress(100);
            } else {

              toast.error(error.message);
              setProgress(100);
            }
          });
      } catch (err) {
        toast.error("Something Wrong");
      }
    };
    // CheckPremiumService
    const userCheckPremiumService = async () => {
      try {
        await axios(process.env.REACT_APP_API_SERVICE_CHECK_API, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${JSON.parse(
              localStorage.getItem("test_token")
            )}`,

            "Content-Type": "application/json",
          },
        })
          .then((res) => {
            //setProgress(75);
            setCheckPremiumService(res.data);

            setProgress(100);
          })
          .catch((error) => {
            if (error.response) {

              // toast.warning(error.response.data.message);
              setProgress(100);
            } else if (error.request) {

              toast.error(error.request);
              setProgress(100);
            } else {

              toast.error(error.message);
              setProgress(100);
            }
          });
      } catch (err) {
        toast.error("Something Wrong");
      }
    };

    // wallet
    const userWallet = async () => {
      try {
        await axios(process.env.REACT_APP_WALLET_API, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${JSON.parse(
              localStorage.getItem("test_token")
            )}`,

            "Content-Type": "application/json",
          },
        })
          .then((res) => {
            //setProgress(75);
            setwallet(res.data);

            setProgress(100);
          })
          .catch((error) => {
            if (error.response) {

              //toast.warning(error.response.data.message);

              setProgress(100);
            } else if (error.request) {

              toast.error(error.request);
              setProgress(100);
            } else {
              toast.error(error.message);
              setProgress(100);
            }
          });
      } catch (err) {
        toast.error("Something Wrong");
      }
    };

    const raeList = async () => {
      try {
        await axios(process.env.REACT_APP_API_REFER_EARN_LIST, {
          method: "GET",
        })
          .then((res) => {
            setProgress(75);
            setraeData(res.data.list);

            setProgress(100);
          })
          .catch((error) => {
            if (error.response) {

              toast.warning(" Please login");

              setProgress(100);
            } else if (error.request) {

              toast.error(error.request);
              setProgress(100);
            } else {
              toast.error(error.message);
              setProgress(100);
            }
          });
      } catch (err) {
        toast.error("Something Wrong");
      }
    };

    raeList()

    userData();
    userCheckPremiumService();
    userWallet();
  }, []);

  //  user info update
  const userDataUpdate = async (e) => {
    setProgress(10);

    try {
      setProgress(75);
      e.preventDefault();
      await axios(process.env.REACT_APP_API_USER_PROFILE_UPDATE, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${JSON.parse(
            localStorage.getItem("test_token")
          )}`,

          "Content-Type": "application/json",
        },
        data: {
          name: name,
          email: email,
          address: address,
          date_of_birth: dob,
        },
      })
        .then((res) => {
          setProgress(100);

          toast.success(res.data.message);
          setIsOpen(!isOpen);
          //navigat('/userprofile');
          window.location.reload(false);
          //window.location.href = "http://localhost:3000/userprofile"
        })
        .catch((error) => {
          if (error.response) {

            toast.warning(" Please login");

            setProgress(100);
          } else if (error.request) {
            ;
            toast.error(error.request);
            setProgress(100);
          } else {

            toast.error(error.message);
            setProgress(100);
          }
        });
    } catch (err) {
      setProgress(100);
      toast.error("Something Wrong");
    }
  };

  // userChangePassword
  // const userChangePassword = async (e) => {
  //   setProgress(10);

  //   try {
  //     setProgress(75);
  //     e.preventDefault();
  //     await axios(process.env.REACT_APP_API_USER_CHANGE_PASSWORD, {
  //       method: "POST",
  //       headers: {
  //         Authorization: `Bearer ${JSON.parse(
  //           localStorage.getItem("test_token")
  //         )}`,

  //         "Content-Type": "application/json",
  //       },
  //       data: {
  //         new_pass: newPass,
  //         conf_pass: confirmPass,
  //       },
  //     })
  //       .then((res) => {
  //         setProgress(100);

  //         toast.success(res.data.message);
  //         setPassOpen(!passOpen);
  //         //window.location.href = "http://localhost:3000/userprofile"
  //       })
  //       .catch((error) => {
  //         if (error.response) {

  //           toast.warning(" Please login");

  //           setProgress(100);
  //         } else if (error.request) {

  //           toast.error(error.request);
  //           setProgress(100);
  //         } else {

  //           toast.error(error.message);
  //           setProgress(100);
  //         }
  //       });
  //   } catch (err) {
  //     setProgress(100);
  //     toast.error("Something Wrong");
  //   }
  // };

  // user logout
  const userLoguot = () => {
    setProgress(75);
    localStorage.removeItem("test_token");
    toast.success("User logged out");
    setProgress(100);
    //window.location.reload(false);
    navigat("/login");
    //window.location.href = "http://localhost:3000/login";
  };

  // img uplode
  const handleImg = (e) => {
    setImgUplod(e.target.files[0]);

  };

  const userUploadImgApi = async () => {

    const formData = new FormData();
    formData.append("image", imgUplod);
    try {
      await axios(process.env.REACT_APP_API_USER_PROFILE_IMG_UPDATE, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${JSON.parse(
            localStorage.getItem("test_token")
          )}`,
          "content-type": "multipart/form-data",
        },
        data: formData,
      })
        .then((res) => {
          setProgress(100);

          toast.success(res.data.message);
          window.location.reload(false);
          //navigat('/userprofile');
          //window.location.href = "http://localhost:3000/userprofile";
        })
        .catch((error) => {
          if (error.response) {

            toast.warning(" Please login");
            ;
            setProgress(100);
          } else if (error.request) {
            ;
            toast.error(error.request);
            setProgress(100);
          } else {

            toast.error(error.message);
            setProgress(100);
          }
        });
    } catch (err) {
      setProgress(100);
      toast.error("Something Wrong");
    }
  };

  const copyReferalCode = () => {
    navigator.clipboard.writeText(userInfo.referal_code);
    toast.success("Coppy");
  };

  const servicesHandler = async () => {
    try {
      setProgress(75);
      await axios(process.env.REACT_APP_API_SERVICE_HISTORY, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${JSON.parse(
            localStorage.getItem("test_token")
          )}`,

          "Content-Type": "application/json",
        },
      })
        .then((res) => {

          // toast.success(res.data.message);
          setserviceList(res.data.list);
          setIsListOper(!isListOper);
          setProgress(100);
          //window.location.href = "http://localhost:3000/userprofile"
        })
        .catch((error) => {
          if (error.response) {

            toast.warning(" Please login");

            setProgress(100);
          } else if (error.request) {

            toast.error(error.request);
            setProgress(100);
          } else {

            toast.error(error.message);
            setProgress(100);
          }
        });
    } catch (err) {
      setProgress(100);
      toast.error("Something Wrong");
    }
  };


  const [nameErr, setnameErr] = useState(false)
  const [phoneErr, setphoneErr] = useState(false)


  //userName_Vault
  const nameHandler = (e) => {
    let item = e.target.value;
    const isValidName = /^[a-zA-Z]+ [a-zA-Z]/;

    if (isValidName.test(item)) {
      setnameErr(false)
    } else {
      setnameErr(true)
    }
    setdName(item)
  }

  //userPhone_Vault
  const phoneHandler = (e) => {
    let item = e.target.value;
    if (item.length < 10) {
      setphoneErr(true)
    }
    else {
      setphoneErr(false)
    }
    setnumber(item)
  }
  const addressHandler = (e) => {
    let item = e.target.value;
    setdAddress(item);
  }




  const handleSaveBtn = async (e) => {
    setProgress(10);
    try {
      setProgress(75);
      e.preventDefault();
      await axios(
        process.env.REACT_APP_API_ADD_DELIVERY_ADDRESS,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${JSON.parse(
              localStorage.getItem("test_token")
            )}`,

            "Content-Type": "application/json",
          },
          data: {
            contact_person_name: dname,
            address_type: type,
            address: daddress,
            city: city,
            pin_code: pinCord,
            phone: number,
            state: state,
            country: country,
          },
        }
      )
        .then((res) => {
          setProgress(100);

          toast.success(res.data.message);
          setcheckout(!checkout)
          //navigat('/userprofile');
          //window.location.reload(false);
          //window.location.href = "http://localhost:3000/userprofile"
        })
        .catch((error) => {
          if (error.response) {
            // status code out of the range of 2xx

            toast.warning(" Please login");
            setProgress(100);


          } else if (error.request) {
            // The request was made but no response was received

            toast.error(error.request);
            setProgress(100);
          } else {
            // Error on setting up the request

            toast.error(error.message);
            setProgress(100);
          }
        });
    } catch (err) {
      setProgress(100);
      toast.error("Something Wrong");
    }
  };

  // const copyShar = () => {
  //   navigator.clipboard.writeText("https://kaeinyonne-react.web.app/");
  //   toast.success("Coppy");
  // };

  return (
    <div className="min-h-screen">
      <div className=" grid gap-3 sm:grid-cols-2  lg:grid-cols-3 mt-5 mb-6">
        <>
          <LoadingBar
            color="#FF8000"
            progress={progress}
            onLoaderFinished={() => setProgress(0)}
          />

          <div className=" lg:mt-6 md:m-4 md:p-5">
            <div className="shadow-xl rounded-lg ">
              <div className="px-4 py-3 shadow-lg bg-gradient-to-r from-[#217CD1] to-[#164574] flex items-center gap-4  rounded-md">
                <div

                  className="inline-flex items-top cursor-pointer"
                >
                  <img
                    src={`${imgUrl}${userInfo.profile_image}`}
                    alt=""
                    onError={(event) => event.target.src = UserIcon}
                    loading="lazy"
                    className="rounded-full w-24 h-24 border border-white object-cover"
                  />
                  <FaEdit onClick={() => setImgOpen(!imgOpen)} className="text-white " />

                  {/* <BsFillShareFill onClick={copyShar} className=" ml-2 text-white" /> */}

                </div>


                <div className="flex-grow">
                  <p className=" text-white ">Hello,</p>
                  <h4 className="text-white  font-medium">{userInfo.name}</h4>
                </div>
              </div>
              <div className="mt-6 bg-white shadow rounded-lg  p-4 divide-y divide-gray-200 space-y-4 text-gray-600">
                <div className="space-y-1 pl-8">
                  <h1
                    href="#"
                    className="relative text-primary block font-medium capitalize transition"
                  >
                    <span className="absolute -left-8 top-0 text-base">
                      <i className="fa-regular fa-address-card"></i>
                    </span>
                    Manage account
                  </h1>
                  {/* <a href="#" className="relative hover:text-primary block capitalize transition">
                  Profile information
                </a> */}
                  <button
                    onClick={() => setcheckout(!checkout)}
                    className="relative hover:text-primary block capitalize transition"
                  >
                    Manage addresses
                  </button>
                  <Link to={"/forgotpassword"}
                    // onClick={() => setPassOpen(!passOpen)}
                    className="relative hover:text-primary block capitalize transition"
                  >
                    Change password
                  </Link>
                </div>

                <div className="space-y-1 pl-8 pt-4">
                  <h1
                    href="#"
                    className="relative hover:text-primary block font-medium capitalize transition"
                  >
                    <span className="absolute -left-8 top-0 text-base">
                      <i className="fa-solid fa-box-archive"></i>
                    </span>
                    My order
                  </h1>
                  <Link
                    to="/Transactionhistory"
                    className="relative hover:text-primary block capitalize transition"
                  >
                    Transaction History
                  </Link>
                  <Link
                    to="/carthistory"
                    className="relative hover:text-primary block capitalize transition"
                  >
                    My cart history
                  </Link>
                  <Link
                    to="/wishlist"
                    className="relative hover:text-primary block capitalize transition"
                  >
                    My wishlist
                  </Link>
                  <button
                    onClick={servicesHandler}
                    className="relative hover:text-primary block capitalize transition"
                  >
                    My Services
                  </button>
                  {/* <button className="relative hover:text-primary block capitalize transition">
                    My reviews
                  </button> */}
                </div>
                <div className="space-y-1 pl-8 pt-4">
                  <button
                    onClick={userLoguot}
                    className="relative hover:text-primary block font-medium capitalize transition"
                  >
                    <span className="absolute -left-8 top-0 text-base">
                      <i className="fa-regular fa-arrow-right-from-bracket"></i>
                    </span>
                    Logout
                  </button>
                </div>
              </div>
            </div>
          </div>

          {isListOper && (
            <div
              className="relative z-10"
              aria-labelledby="modal-title"
              role="dialog"
              aria-modal="true"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

              <div className="fixed inset-0 z-10 overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                  <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                    <div className="px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                      <div className=" sm:items-start">
                        <div className="mt-6">
                          <div className="relative mb-4 text-left  ">
                            <div className="mb-4">
                              <div className="flex flex-col container max-w-md mt-10 mx-auto w-full items-center justify-center bg-whit rounded-lg shadow">
                                <ul className="flex flex-col divide-y w-full">
                                  {serviceList.map((serviceItem) => (
                                    <li
                                      key={serviceItem.id}
                                      className="flex flex-row"
                                    >
                                      <div className="select-none cursor-pointer flex flex-1 items-center p-4">
                                        <div className="flex-1 pl-1">
                                          <div className="font-medium text-gray-600">
                                            Start
                                          </div>
                                          <div className="text-gray-600 text-sm">
                                            {serviceItem.purchase_date}
                                          </div>
                                        </div>
                                        <div className="flex-1 pl-1">
                                          <div className="font-medium text-gray-600">
                                            End
                                          </div>
                                          <div className="text-gray-600 text-sm">
                                            {serviceItem.expiry_date}
                                          </div>
                                        </div>
                                        <div className="flex-1 pl-1">
                                          <div className="font-medium text-gray-600">
                                            Validity
                                          </div>
                                          <div className="text-gray-600 text-sm">
                                            {serviceItem.validity}
                                          </div>
                                        </div>
                                        <div className="flex flex-row justify-center">
                                          <div className="text-gray-600 text-xs">
                                            ₹ {serviceItem.amount}
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="bg-gray-50 px-4 lg:w-fill py-3 sm:flex sm:flex-row-reverse sm:px-6">
                      <button
                        onClick={() => setIsListOper(!isListOper)}
                        type="button"
                        className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* img update */}

          {imgOpen && (
            <div
              className="relative z-10"
              aria-labelledby="modal-title"
              role="dialog"
              aria-modal="true"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

              <div className="fixed inset-0 z-10 overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                  <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                    <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                      <div className=" sm:items-start">
                        <div className="mt-6">
                          <div className="relative mb-4 text-left  ">
                            <div className="mb-4">
                              <input
                                onChange={handleImg}
                                type="file"
                                name="file"
                                className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="bg-gray-50 px-4 lg:w-fill py-3 sm:flex sm:flex-row-reverse sm:px-6">
                      <button
                        onClick={() => setImgOpen(!imgOpen)}
                        type="button"
                        className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                      >
                        Cancel
                      </button>
                      <button
                        disabled={!imgUplod}
                        onClick={userUploadImgApi}
                        type="button"
                        className={`inline-flex w-full justify-center ${!imgUplod
                          ? "cursor-no-drop bg-gray-500 hover:bg-gray-700"
                          : "cursor-pointer bg-green-500 hover:bg-green-700"
                          } rounded-md border border-transparent  px-4 py-2 text-base font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm`}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* {passOpen && (
            <div
              className="relative z-10"
              aria-labelledby="modal-title"
              role="dialog"
              aria-modal="true"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

              <div className="fixed inset-0 z-10 overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                  <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                    <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                      <div className=" sm:items-start">
                        <div className="mt-6">
                          <div className="relative mb-4 text-left  ">
                            <div className="mb-4">
                              <input
                                onChange={(e) => setNewPass(e.target.value)}
                                type={changePassword ? "password" : "text"}
                                className="form-control relative block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                                placeholder="New password"
                              />
                              <span
                                onClick={() => {
                                  setChangePassword(changeIcon);
                                }}
                                className="absolute right-3 top-2 cursor-pointer"
                              >
                                {changeIcon ? (
                                  <AiFillEye size={20} />
                                ) : (
                                  <AiFillEyeInvisible size={20} />
                                )}
                              </span>
                            </div>
                            <div className="mb-4">
                              <input
                                onChange={(e) => setConfirmPass(e.target.value)}
                                type={changePassword ? "password" : "text"}
                                className="form-control relative block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                                placeholder="Confirm password"
                              />
                              <span
                                onClick={() => {
                                  setChangePassword(changeIcon);
                                }}
                                className="absolute right-3 top-16 cursor-pointer"
                              >
                                {changeIcon ? (
                                  <AiFillEye size={20} />
                                ) : (
                                  <AiFillEyeInvisible size={20} />
                                )}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="bg-gray-50 px-4 lg:w-fill py-3 sm:flex sm:flex-row-reverse sm:px-6">
                      <button
                        onClick={() => setPassOpen(!passOpen)}
                        type="button"
                        className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                      >
                        Cancel
                      </button>

                      <button
                        disabled={!newPass || !confirmPass}
                        onClick={userChangePassword}
                        type="button"
                        className={`inline-flex w-full justify-center ${!newPass || !confirmPass
                          ? "cursor-no-drop bg-gray-500 hover:bg-gray-700"
                          : "cursor-pointer bg-green-500 hover:bg-green-700"
                          } rounded-md border border-transparent  px-4 py-2 text-base font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm`}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )} */}
         

          <div className="m-3 grid mb-3 sm:grid-cols-1 lg:grid-cols-1">
            <div className="px-4 py-6 shadow-lg mt-9 bg-gradient-to-r from-blue-400 to-emerald-400 rounded-lg">
              <span className="h-14 w-14 rounded-xl text-amber-300">
                <FaUserAlt size={25} />
              </span>
              <div className="flex items-center justify-between mb-4">
                <h3 className="font-medium text-white text-lg">
                  Personal Profile
                </h3>
                <button
                  onClick={() => setIsOpen(!isOpen)}
                  className="text-primary"
                >
                  {<FaEdit size={20} className="text-white" />}
                </button>
              </div>

              <div className="space-y-1">
                <h4 className="text-white font-medium">{userInfo.name}</h4>
                <p className="text-white">{userInfo.email}</p>
                <p className="text-white">{userInfo.phone}</p>
                <p className="text-white">{userInfo.date_of_birth}</p>
                <p className="text-white">{userInfo.address}</p>
              </div>
            </div>
            {isOpen && (
              <div
                className="relative z-10"
                aria-labelledby="modal-title"
                role="dialog"
                aria-modal="true"
              >
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                  <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                      <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                        <div className=" sm:items-start">
                          <div className="mt-6">
                            <div className="relative mb-4 text-left  ">
                              <div className="mb-4">
                                <input
                                  onChange={(e) => setName(e.target.value)}
                                  type="text"
                                  className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                                  placeholder="Name"
                                />
                              </div>
                              <div className="mb-4">
                                <input
                                  onChange={(e) => setEmail(e.target.value)}
                                  type="enail"
                                  className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                                  placeholder="Email"
                                />
                              </div>
                              <div className="mb-4">
                                <input
                                  onChange={(e) => setAddress(e.target.value)}
                                  type="text"
                                  className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                                  placeholder="Address"
                                />
                              </div>
                              <div className="mb-4">
                                <input
                                  onChange={(e) => setDob(e.target.value)}
                                  type="date"
                                  className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                                  placeholder="Date of birth"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="bg-gray-50 px-4 lg:w-fill py-3 sm:flex sm:flex-row-reverse sm:px-6">
                        <button
                          onClick={() => setIsOpen(!isOpen)}
                          type="button"
                          className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                        >
                          Cancel
                        </button>

                        <button
                          disabled={!email || !name || !dob || !address}
                          onClick={userDataUpdate}
                          type="button"
                          className={`inline-flex w-full justify-center ${!email || !name || !dob || !address
                            ? "cursor-no-drop bg-gray-500 hover:bg-gray-700"
                            : "cursor-pointer bg-green-500 hover:bg-green-700"
                            } rounded-md border border-transparent  px-4 py-2 text-base font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm`}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="px-4 py-6 shadow-lg mt-9 bg-gradient-to-r from-blue-500 to-sky-400 rounded-lg">
              <span className="h-14 w-14 rounded-xl  text-green-300 ">
                <IoWalletSharp size={30} />
              </span>
              <div className="flex items-center justify-between mb-4">
                <h3 className="font-medium text-white text-lg">Wallet</h3>
              </div>
              <p className="mt-2 text-xl text-white font-medium flex">
              Today's Earn <img src={coin} alt="" className="h-7 w-9" /> {wallet.today_total_balance}
              </p>

              <p className="mt-2 text-xl text-white font-medium flex">
              Total Earn <img src={coin} alt="" className="h-7 w-9" /> {wallet.total_balance}
              </p>
              {/* <span className="text-xs text-gray-400">+4.9%</span> */}
            </div>
          </div>
          <div className="m-3 grid gap-3 sm:grid-cols-1 lg:grid-cols-1">
            <div className="px-4 py-6 shadow-lg mt-9 bg-gradient-to-r from-purple-400 to-indigo-300 rounded-lg">
              <span className="h-14 w-14 rounded-xl text-orange-300">
                <HiUserGroup size={30} />
              </span>
              <div className="flex items-center justify-between mb-4">
                <h3 className="font-medium text-white text-lg">Refer & Earn</h3>
                <button
                  onClick={copyReferalCode}
                  className="text-primary text-white"
                >
                  {<FiCopy size={22} />}
                </button>
              </div>
              {raeData.map((item) =>
                <div key={item.id} className="space-y-1 flex">
                  <h4 className="text-white font-medium inline-flex capitalize ">
                  {item.type}
                  </h4>
                  <span className=" text-white ml-2 flex"> <img src={coin} alt="" className="h-7 w-9" /> {item.amount}</span>
                </div>
              )}
            </div>
            <div className="px-4 py-6 shadow-lg mt-9 Pastel bg-gradient-to-r from-[#1966ad] to-[#5b87b3]  rounded-lg">
              <span className="h-14 w-14 rounded-xl text-fuchsia-300">
                <MdMiscellaneousServices size={33} />
              </span>
              <div className="flex items-center justify-between mb-4">
                <h3 className="font-medium text-white text-lg">
                  Premium Service
                </h3>
                <button
                  onClick={() => navigat("/service")}
                  className="text-primary text-white"
                >
                  {<RxUpdate size={22} />}
                </button>
              </div>

              {checkPremiumService.avaliable === "no" ? (
                <p className="text-white font-medium">
                  {checkPremiumService.message}
                </p>
              ) : (
                <div className="space-y-1">
                  <h4 className="text-white font-medium  ">
                    Service start date
                  </h4>
                  {/* <span className=' text-white ml-2'>11/11/2023</span> */}
                  <p className="text-white">
                    {checkPremiumService.purchased_date}
                  </p>
                  <p className="text-white font-medium">Existing plan</p>
                  <p className="text-white">
                    Left {checkPremiumService.left_days} days
                  </p>
                </div>
              )}
            </div>
          </div>
        </>
      </div>




      {checkout && (
        <div
          className="relative z-10"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <div className="bg-white px-6 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div className="">

                    <div className="mx-auto max-w-lg px-4 lg:px-8">
                      <form className="grid grid-cols-6 gap-4">
                        <div className="col-span-3">
                          <label className="block text-xs font-medium text-gray-700 dark:text-gray-900">
                            Full Name
                          </label>
                          <input
                            onChange={nameHandler}
                            type="text"
                            value={name}
                            placeholder="Full Name"
                            className="mt-1  flex h-10 w-full rounded-md border border-blue-500 bg-transparent py-2 px-3 text-sm text-gray-900 focus:outline-none focus:ring-1  focus:ring-offset-1  focus:ring-offset-blue-400"
                          />
                          {nameErr ? <label className=" text-red-500 ml-3">Enter a valid name</label> : ""}
                        </div>
                        <div className="col-span-3">
                          <label className="block text-xs font-medium text-gray-700 dark:text-gray-900">
                            Phone
                          </label>
                          <input
                            onChange={phoneHandler}
                            type="number"
                            id="number"
                            value={number}
                            placeholder="Phone Number"
                            className="mt-1  flex h-10 w-full rounded-md border border-blue-500 bg-transparent py-2 px-3 text-sm text-gray-900 focus:outline-none focus:ring-1  focus:ring-offset-1  focus:ring-offset-blue-400 "
                          />

                          {phoneErr ? <label className=" text-red-500 ml-3">Enter a valid phone</label> : ""}
                        </div>
                        <div className="col-span-6">
                          <label className="block text-xs font-medium text-gray-700 dark:text-gray-900">
                            Address
                          </label>
                          <input
                            onChange={addressHandler}
                            type="text"
                            id="add"
                            value={address}
                            placeholder="Address"
                            className="mt-1  flex h-10 w-full rounded-md border border-blue-500 bg-transparent py-2 px-3 text-sm text-gray-900 focus:outline-none focus:ring-1  focus:ring-offset-1  focus:ring-offset-blue-400"
                          />

                        </div>

                        <fieldset className="col-span-6">
                          <div className="mt-1 space-y-2 rounded-md shadow-sm">
                            <div className="flex space-x-2">
                              <div className="flex-1">
                                <label
                                  htmlFor="City"
                                  className="sr-only"
                                >
                                  City
                                </label>
                                <input
                                  type="text"
                                  onChange={(e) => setcity(e.target.value)}
                                  placeholder="City"
                                  value={city}
                                  className="mt-1  flex h-10 w-full rounded-md border border-blue-500 bg-transparent py-2 px-3 text-sm text-gray-900 focus:outline-none focus:ring-1  focus:ring-offset-1  focus:ring-offset-blue-400 "
                                />
                              </div>
                              <div className="flex-1">
                                <label htmlFor="PinCord" className="sr-only">
                                  PinCord
                                </label>
                                <input
                                  type="text"
                                  onChange={(e) => setpinCord(e.target.value)}
                                  placeholder="Pin Code"
                                  value={pinCord}
                                  className="mt-1  flex h-10 w-full rounded-md border border-blue-500 bg-transparent py-2 px-3 text-sm text-gray-900 focus:outline-none focus:ring-1  focus:ring-offset-1  focus:ring-offset-blue-400 "
                                />
                              </div>
                            </div>
                          </div>
                        </fieldset>
                        <fieldset className="col-span-6">
                          <div className="mt-1 space-y-2 rounded-md shadow-sm">
                            <div className="flex space-x-2">
                              <div className="flex-1">
                                <label
                                  htmlFor="Ctate"
                                  className="sr-only"
                                >
                                  State
                                </label>
                                <input
                                  type="text"
                                  onChange={(e) => setstate(e.target.value.toUpperCase())}
                                  placeholder="State"
                                  value={state}
                                  className="mt-1  flex h-10 w-full rounded-md border border-blue-500 bg-transparent py-2 px-3 text-sm text-gray-900 focus:outline-none focus:ring-1  focus:ring-offset-1  focus:ring-offset-blue-400 "
                                />
                              </div>
                              <div className="flex-1">
                                <label htmlFor="Country" className="sr-only">
                                  Country
                                </label>
                                <input
                                  type="text"
                                  onChange={(e) => setcountry(e.target.value)}
                                  placeholder="Country"
                                  value={country}
                                  className="mt-1  flex h-10 w-full rounded-md border border-blue-500 bg-transparent py-2 px-3 text-sm text-gray-900 focus:outline-none focus:ring-1  focus:ring-offset-1  focus:ring-offset-blue-400"
                                />
                              </div>
                            </div>
                          </div>
                        </fieldset>
                        <fieldset className="col-span-6">
                          <legend className="block text-sm font-medium text-gray-700">
                            Address Type
                          </legend>
                          <div className="mt-1 space-y-2 rounded-md shadow-sm">
                            <div>
                              <select
                                id="Country"
                                onChange={(e) => settype(e.target.value)}
                                className="mt-1  flex h-10 w-full rounded-md border border-blue-500 bg-transparent py-2 px-3 text-sm text-gray-900 focus:outline-none focus:ring-1  focus:ring-offset-1  focus:ring-offset-blue-400"
                              >
                                <option>Home</option>
                                <option>Office</option>
                                <option>Other</option>
                                {/* <option>Scotland</option>
                                <option>France</option>
                                <option>Belgium</option>
                                <option>Japan</option> */}
                              </select>
                            </div>
                          </div>
                          {/* 
                          <button
                            onClick={handleSaveBtn}
                            className="roundedcheckout-md bg-green-600 px-3.5 py-1.5 mt-3 w-full text-base font-semibold leading-7 text-white hover:bg-green-500"
                          >
                            save
                          </button>

                          <button
                            onClick={handleSaveBtn}
                            className="rounded-md bg-green-600 px-3.5 py-1.5 mt-3 w-full text-base font-semibold leading-7 text-white hover:bg-green-500"
                          >
                            save
                          </button> */}
                        </fieldset>
                      </form>
                    </div>

                    <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                      <button
                        disabled={!dname || !type || !daddress || !city || !pinCord || !number || !state || !country}
                        onClick={handleSaveBtn} type="button" className={`inline-flex w-full justify-center rounded-md text-white ${!dname || !type || !daddress || !city || !pinCord || !number || !state || !country ? 'cursor-no-drop bg-gray-500 hover:bg-gray-700' : 'cursor-pointer bg-green-500 hover:bg-green-700'} px-3 py-2 text-sm font-semibold  shadow-sm  sm:ml-3 sm:w-auto`}>Save</button>
                      <button onClick={() => setcheckout(!checkout)} type="button" className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto">Cancel</button>
                    </div>

                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Userprofile;
