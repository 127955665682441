import {
  Product_,
  Product_List,
  Product_By_Subcategory,
  Product_By_Category,
  Product_Search,
  Filter
} from "./constant";

export const productList = () => {
  return {
    type: Product_List,
  };
};

export const productBySubcategory = (id) => {
  return {
    type: Product_By_Subcategory,
    id,
  };
};

export const productByCategory = (id) => {
  return {
    type: Product_By_Category,
    id,
  };
};

export const productSearch = (query) => {
  return {
    type: Product_Search,
    query,
  };
};


export const product = (query) => {
  return {
    type: Product_,
    query,
  };
};


export const productFilter = (query, id) => {
  return {
    type: Filter,
    query,
    id
  };
};