import React, { useState, useEffect } from "react";
import LoadingBar from "react-top-loading-bar";
import { AiFillStar } from "react-icons/ai";
//import { AiOutlineShoppingCart } from "react-icons/ai";
import axios from "axios";
import { toast } from "react-custom-alert";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  product
} from "../../redux/productAction";
const Carthistory = () => {
  const [progress, setProgress] = useState(0);

  const [cartData, setcartData] = useState([]);
  let imgUrl = "https://kaeinyonne.com/admin/product_image/";

  const dispatch = useDispatch();

  useEffect(() => {
    const cartItem = async () => {
      try {
        await axios(
          process.env.REACT_APP_API_CART_LIST,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${JSON.parse(
                localStorage.getItem("test_token")
              )}`,

              "Content-Type": "application/json",
            },
          }
        )
          .then((res) => {
            setProgress(75);
        
            setcartData(res.data.cart_list);
         
            setProgress(100);
          })
          .catch((error) => {
            if (error.response) {
             
              toast.warning(error.response.data.message);
             
              setProgress(100);
            } else if (error.request) {
           
              toast.error(error.request);
              setProgress(100);
            } else {
          
              toast.error(error.message);
              setProgress(100);
            }
          });
      } catch (err) {
        toast.error("Something Wrong");
      }
    };

    cartItem();
  }, []);

  const removeItem = async (e) => {

    try {
      await axios(
        process.env.REACT_APP_API_CART_DELETE,
        {
          method: "POST",
          data: {
            cart_id: e.currentTarget.id
          }

        }
      )
        .then((res) => {
          setProgress(75);
        
          window.location.reload(false);
          toast.success(res.data.message);
      
          setProgress(100);
        })
        .catch((error) => {
          if (error.response) {
         
            toast.warning(error.response.data.message);
        
            setProgress(100);
          } else if (error.request) {
      
            toast.error(error.request);
            setProgress(100);
          } else {
        
            toast.error(error.message);
            setProgress(100);
          }
        });
    } catch (err) {
      toast.error("Something Wrong");
    }
  };

  return (
    <section className="py-20 min-h-screen">
      <LoadingBar
        color="#FF8000"
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
      />
      <h1 className="mb-12 text-center font-fontHading text-5xl  text-gray-900">
        Cart History
      </h1>
      <div className="mx-auto  grid max-w-screen-xl grid-cols-1 gap-6 p-6 md:grid-cols-2 lg:grid-cols-3">
        {cartData.map((cartItem) => (
          <article
            key={cartItem.id}
            className="rounded-xl bg-white p-3 shadow-lg hover:shadow-xl"
          >
            <div>
              <div className="relative flex items-end overflow-hidden justify-center">
                <Link
                  onClick={() => dispatch(product(cartItem.product_details.id))}
                  to={`/product/${cartItem.product_details.id}`}
                >
                  <img
                    src={`${imgUrl}${cartItem.product_details.product_image}`}
                    alt=""
                    className="rounded-xl object-fill object-center h-52 w-auto"
                  />
                </Link>
                <div className="absolute bottom-3 left-3 inline-flex items-center rounded-full bg-white p-2 shadow-md">
                  <samp className="text-yellow-400">
                    <AiFillStar />
                  </samp>
                  <span className="text-slate-400 ml-1 text-xs">4.9</span>
                </div>
                {/* <div className="absolute top-3 right-3 inline-flex items-center rounded-full bg-white p-2 shadow-md">
                <samp className=" text-red-400"><FaTimes /></samp>
              </div> */}
              </div>
              <div className="mt-1 p-1">
                <h2 className="text-slate-700">
                  {cartItem.product_details.product_name}
                </h2>
                <p className="text-slate-400 mt-1 text-sm">
                  {cartItem.product_details.small_description}
                </p>

                <div className="mt-2 flex items-end justify-between">
                  <div>
                    <p className="space-x-2">
                      <span className="lg:text-sm md:text-xs text font-semibold">
                        ₹{cartItem.product_details.sale_price_gst}
                      </span>
                      <span className="text-sm line-through text-gray-500">
                        ₹{cartItem.product_details.product_price}
                      </span>
                      <span className="text-xs text-red-700">
                        {(
                          (100 *
                            (cartItem.product_details.product_price -
                              cartItem.product_details.sale_price)) /
                          cartItem.product_details.product_price
                        ).toFixed()}
                        % off
                      </span>
                    </p>
                  </div>
                  <div className="flex justify-center mt-3">
                    <button
                      id={cartItem.id}
                      onClick={removeItem}
                      className="lg:px-2 md:px-1 py-1 ml-2 bg-red-400 hover:bg-red-500 text-center text-sm text-white rounded duration-300"
                    >
                      Remove
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </article>
        ))}
      </div>
    </section>
  );
};

export default Carthistory;
