import React, { useState, useEffect } from 'react'
import axios from "axios";
import { toast } from "react-custom-alert";
// import Img from '../../assets/logo2.png';
import LoadingBar from "react-top-loading-bar";
import { useNavigate } from 'react-router-dom'
import Giftc from '../../assets/giftC.jpg';

const Gift = () => {

  const navigat = useNavigate()


  const [progress, setProgress] = useState(0);
  const [giftCard, setgiftCard] = useState([])
  const [imgUrl, setImgUrl] = useState()


  const [id, setid] = useState()
  const [ammount, setAmmount] = useState()
  const [name, setName] = useState()
  const [email, setEmail] = useState()
  const [phone, setPhone] = useState()
  const [msg, setmsg] = useState()



  const [isOpen, setIsOpen] = useState(false);


  const [userNameErr, setUserNameErr] = useState(false)
  const [phoneErr, setPhoneErr] = useState(false);
  const [emailErr, setemailErr] = useState(false)
  const [giftedEmailErr, setgiftedEmailErr] = useState(false)
  const [giftedPhoneErr, setgiftedPhoneErr] = useState(false)

  const [giftedEmail, setgiftedEmail] = useState()
  const [giftedPhone, setgiftedPhone] = useState()



  useEffect(() => {

    if (!localStorage.getItem('test_token')) {
      navigat('/login')
    }

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth"
    });


    const giftCardApi = async () => {

      try {
        setProgress(75)
        await axios(process.env.REACT_APP_API_GIFT_CARD_API, {
          method: 'get'
        }).then((res) => {

          setImgUrl(res.data.baseUrl)
          setgiftCard(res.data.gift_card_list)
          setProgress(100)
        }).catch((error) => {
          if (error.response) {

            toast.warning(error.response.data.message)

            setProgress(100)

          } else if (error.request) {

            toast.error(error.request)
            setProgress(100)
          } else {

            toast.error(error.message)
            setProgress(100)
          }
        })

      } catch (err) {
        toast.error('Something Wrong')
      }

    }

    giftCardApi()
  }, [navigat])


  const handelGiftCard = (e) => {
    setAmmount(e.currentTarget.value)
    setid(e.currentTarget.id)
    setIsOpen(!isOpen)
  }


  //userName_Vault
  const userNameHandler = (e) => {
    let item = e.target.value;
    const isValidName = /^[a-zA-Z]+ [a-zA-Z]/;

    if (isValidName.test(item)) {
      setUserNameErr(false)
    } else {
      setUserNameErr(true)
    }
    setName(item)


  }

  //userEmail_Vault
  const emailHandler = (e) => {
    let item = e.target.value;
    const isValidEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

    if (isValidEmail.test(item)) {
      setemailErr(false)
    } else {
      setemailErr(true)
    }
    setEmail(item)
  }

  //userPhone_Vault
  const phoneHandler = (e) => {
    let item = e.target.value;
    if (item.length < 10) {
      setPhoneErr(true)
    }
    else {
      setPhoneErr(false)
    }
    setPhone(item)
  }

  const message = (e) => {
    setmsg(e.target.value)
  }

  //userEmail_Vault
  const geftedEmailHandler = (e) => {
    let item = e.target.value;
    const isValidEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

    if (isValidEmail.test(item)) {
      setgiftedEmailErr(false)
    } else {
      setgiftedEmailErr(true)
    }
    setgiftedEmail(item)
  }

  //userPhone_Vault
  const geftedPhoneHandler = async (e) => {
    let item = e.target.value;
    try {
      //setProgress(75)
      await axios(
        process.env.REACT_APP_API_GIFT_CARD_NUMBER_CHECK_API,
        {
          method: "POST",
          data: {
            phone: item,
          }
        }
      ).then((res) => {
        setgiftedPhoneErr(false)
        setgiftedPhone(item)



      }).catch((error) => {
        if (error.response) {


          setgiftedPhoneErr(true)


        } else if (error.request) {

          toast.error(error.request)

        } else {
          setgiftedPhoneErr(true)

        }
      });

    } catch (err) {
      toast.error('Something Wrong')
    }
  }


  const SubmitRazorpay = () => {
    setIsOpen(!isOpen)

    try {
      // console.log("ggg", !emailErr && !userNameErr)
      if (!emailErr && !userNameErr && !phoneErr && !giftedEmailErr && !giftedPhoneErr) {
        setProgress(75)
        axios(
          process.env.REACT_APP_API_GIFT_CARD_PAY_API,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${JSON.parse(localStorage.getItem('test_token'))}`,

              "Content-Type": "application/json",
            },
            data: {

              gifted_user_email: giftedEmail,
              gifted_user_phone: giftedPhone,
              amount: ammount,
              gift_card_id: id,
              message: msg,
              //order_id: response.razorpay_order_id,
              //transaction_id: response.razorpay_payment_id,

            }
          }
        ).then((res) => {
          setProgress(100)
          // console.log("response from api is ", res.data);
          const url = res.data.key;
          // console.log("url", url);
          // console.log("URL to Open:", url);
          window.open(url, "_blank");
        }).catch((error) => {
          if (error.response) {

            toast.warning(error.response.data.message)

            setProgress(100)
          } else if (error.request) {

            toast.error(error.request)
            setProgress(100)
          } else {

            toast.error(error.message)
            setProgress(100)
          }
        });

      } else {
        toast.warning("email is wrong")
      }

    } catch (err) {
      toast.error('Something Wrong')
    }
  }

  return (
    <div>
      <LoadingBar
        color="#FF8000"
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
      />

      <section className="py-8 ">
        <div className="container px-4 mx-auto ">
          <div className=" bg-indigo-500 rounded">
            <div className="flex flex-wrap-reverse">
              <div className="w-full md:w-1/2 pt-6 mb-10 md:mb-0 p-3">
                <h3 className="mb-1 text-2xl font-bold text-white">
                  <span className="text-green-300">Try For Free</span>
                  <span>New Features</span>
                </h3>
                {/* <p className="mb-8 md:mb-16 text-sm font-medium text-indigo-100">No charge for seven days</p> */}
              </div>
              <div className="w-full md:w-1/2 flex items-center">
                <img className="mx-auto h-full rounded" src={Giftc} alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="py-15 bg-blueGray-50 overflow-hidden min-h-screen ">
        <div className="container px-2 mx-auto">
          <div className="md:max-w-6xl mx-auto">
            <div className="flex flex-wrap -m-3.5 mb-10">
              {giftCard.map((gift) =>
                <div key={gift.id} className="w-full md:w-1/3 p-1">
                  <div className="relative h-full rounded-xl">
                    <div className="relative z-10 flex flex-col justify-between h-full">
                      <img src={`${imgUrl}${gift.image}`} alt="" className=' relative-lg p-2' />
                      <p className="text-lg text-center font-medium leading-4 text-gray-800">
                        {gift.name}
                      </p>
                      <p className="space-x-2 ml-12 mt-4">
                        <span className="text-2xl font-semibold">₹ {gift.offer_price}</span>
                        <span className="text-sm line-through text-gray-500">
                          ₹ {gift.price}
                        </span>
                        <span className="text-sm text-red-700">{(100 * (gift.price - gift.offer_price) / gift.price).toFixed()}% off</span>
                        <button id={gift.id} value={gift.offer_price} onClick={handelGiftCard} className="px-6 py-2 bg-green-600 hover:bg-amber-600 text-center text-sm text-white rounded duration-300">
                          Buy
                        </button>
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </div>


            {isOpen && (
              <div
                className="relative z-10"
                aria-labelledby="modal-title"
                role="dialog"
                aria-modal="true"
              >
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                  <div className="flex h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <div className="relative mt-4 transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                      <div className="">
                        <div className=" sm:items-start">

                          <div className="bg-white rounded shadow-lg p-4 px-4 md:p-8">
                            <div className="grid gap-4 gap-y-2 text-sm grid-cols-1 lg:grid-cols-3">
                              <div className="text-gray-600">
                                <p className="font-medium text-lg">Personal Details</p>
                                <p>Please fill out all the fields.</p>
                              </div>

                              <div className="lg:col-span-2">
                                <div className="grid gap-4 gap-y-2 text-sm grid-cols-1 md:grid-cols-5">
                                  <div className="md:col-span-5">
                                    {/* //<label htmlFor="full_name">Full Name</label> */}
                                    <input onChange={userNameHandler} type="text" placeholder="Full Name" name="full_name" id="full_name" className="h-10 border mt-1 rounded px-4 w-full bg-gray-50" />
                                    {userNameErr ? <label className=" text-red-500 ml-3">Enter a valid name</label> : ""}
                                  </div>

                                  <div className="md:col-span-5">

                                    <input onChange={emailHandler} type="email" name="email" className="h-10 border mt-1 rounded px-4 w-full bg-gray-50" placeholder="Email Address" />
                                    {emailErr ? <label className=" text-red-500 ml-3">Enter a valid email</label> : ""}
                                  </div>

                                  <div className="md:col-span-5">

                                    <input onChange={phoneHandler} type="number" name="phone" className="h-10 border mt-1 rounded px-4 w-full bg-gray-50" placeholder="Mobile Number" />
                                    {phoneErr ? <label className=" text-red-500 ml-3">Enter a valid number</label> : ""}
                                  </div>

                                  <div className="md:col-span-5">

                                    <input onChange={geftedPhoneHandler} type="number" name="phone" className="h-10 border mt-1 rounded px-4 w-full bg-gray-50" placeholder="Gifted User Mobile Number" />
                                    {giftedPhoneErr ? <label className=" text-red-500 ml-3">Enter a valid number</label> : ""}
                                  </div>


                                  <div className="md:col-span-5">

                                    <input onChange={geftedEmailHandler} type="email" name="email" className="h-10 border mt-1 rounded px-4 w-full bg-gray-50" placeholder="Gifted User Email Address" />
                                    {giftedEmailErr ? <label className=" text-red-500 ml-3">Enter a valid number</label> : ""}
                                  </div>

                                  <div className="md:col-span-5">

                                    <input onChange={message} type="text" name="message" className="h-10 border mt-1 rounded px-4 w-full bg-gray-50" placeholder="Message" />

                                  </div>

                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="bg-gray-50 px-4 lg:w-fill py-3 sm:flex sm:flex-row-reverse sm:px-6">
                        <button
                          onClick={() => setIsOpen(!isOpen)}
                          type="button"
                          className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                        >
                          Cancel
                        </button>
                        <button
                          disabled={!phone || !email || !name || !giftedPhone || !giftedEmail}
                          onClick={SubmitRazorpay}
                          type="button"
                          className={`inline-flex w-full justify-center ${!phone || !email || !name || !giftedPhone || !giftedEmail ? 'cursor-no-drop bg-gray-500 hover:bg-gray-700' : 'cursor-pointer bg-green-500 hover:bg-green-700'} rounded-md border border-transparent  px-4 py-2 text-base font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm`}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
    </div>
  )
}

export default Gift
