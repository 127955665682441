import React, { useEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
//import { FiShoppingBag } from "react-icons/fi";
import { AiFillHeart } from "react-icons/ai";
//import { AiFillStar } from "react-icons/ai"
import { Link } from "react-router-dom";
import LoadingBar from "react-top-loading-bar";
import axios from "axios";
import { toast } from "react-custom-alert";
import { useDispatch } from "react-redux";
import { product } from "../../redux/productAction";
import { calculatPercentage } from "../../utils/calculatPercentage";
const DealOfTheday = () => {
  const dispatch = useDispatch();

  const [datadotd, setdatadotd] = useState([]);
  const [dataUrl, setdataUrl] = useState();
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const oderList = async () => {
      try {
        await axios(process.env.REACT_APP_API_DEALS_OF_THE_DAY, {
          method: "GET",
        })
          .then((res) => {
            setProgress(75);
            setdatadotd(res.data.product_list);

            setdataUrl(res.data.baseUrl);
            // console.log(res.data.wishlist[1].product_details[1].id)
            setProgress(100);
          })
          .catch((error) => {
            if (error.response) {
              // status code out of the range of 2xx

              toast.warning(error.response.data.message);

              setProgress(100);
            } else if (error.request) {
              // The request was made but no response was received

              toast.error(error.request);
              setProgress(100);
            } else {
              // Error on setting up the request

              toast.error(error.message);
              setProgress(100);
            }
          });
      } catch (err) {
        toast.error("Something Wrong");
      }
    };

    oderList();
  }, [dataUrl]);

  // wishList
  const wishlistBtn = async (e) => {
    try {
      await axios(process.env.REACT_APP_API_ADD_WISHLIST, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${JSON.parse(
            localStorage.getItem("test_token")
          )}`,

          "Content-Type": "application/json",
        },
        data: {
          product_id: e.currentTarget.id,
        },
      })
        .then((res) => {
          setProgress(75);
          toast.success(res.data.message);

          setProgress(100);
        })
        .catch((error) => {
          if (error.response) {
            toast.warning(error.response.data.message);

            setProgress(100);
          } else if (error.request) {
            toast.error(error.request);
            setProgress(100);
          } else {
            toast.error(error.message);
            setProgress(100);
          }
        });
    } catch (err) {
      toast.error("Something Wrong");
    }
  };

  return (
    <div>
      <LoadingBar
        color="#FF8000"
        progress={progress}
        height={2}
        onLoaderFinished={() => setProgress(0)}
      />
      <p className="text-3xl font-fontHading leading-9 text-left ml-10 text-gray-800 lg:text-4xl mt-2">
        Deal Of The Week
      </p>
      <div
        className="
      flex
      justify-center
      items-center
      flex-col
      px-2
      md:px-6
      xl:px-9
      py-3
      md:py-3
      xl:py-1
    "
      >
        <OwlCarousel
          className="owl-theme grid md:grid-cols-2 lg:grid-cols-4 justify-items-between mt-2 gap-y-8 lg:gap-y-0 gap-x-8"
          loop
          margin={6}
          autoplay={true}
          dots={false}
          autoPlay={true}
          autoplayTimeout={2500}
          autoplaySpeed={4000}
          autoplayHoverPause={true}
          items={8}
          autoWidth={true}
        >
          {datadotd.map((item) => (
            <div key={item.id} className="item flex items-start flex-col">
              <div className="relative flex justify-center items-center bg-gray-50 py-1 px-1 rounded-md">
                <Link
                  onClick={() => dispatch(product(item.id))}
                  to={`/product/${item.id}`}
                >
                  <img
                    className="rounded-md object-fill object-center h-44 w-44"
                    src={dataUrl + item.product_image}
                    alt="mobile"
                  />
                </Link>
                <div className="absolute top-3 right-3 inline-flex items-center rounded-full bg-white p-2 shadow-md">
                  <button id={item.id} onClick={wishlistBtn}>
                    <AiFillHeart
                      className="text-red-400 hover:text-red-600"
                      size={18}
                    />
                  </button>
                </div>
                {/* <button className="absolute top-16 right-3 flex justify-center items-center p-3.5 bg-white rounded-full">
                <AiFillHeart
                  className="text-red-400 hover:text-red-600"
                  size={18}
                />
              </button> */}
              </div>
              <div className="flex flex-col items-start jusitfy-start mt-3 space-y-3">
                {/* <div className="absolute ml-56 inline-flex items-center rounded-lg bg-white p-2">
                <samp className="text-yellow-400"><AiFillStar /></samp>
                <span className="text-slate-900 ml-1 text-md">4.9</span>
              </div> */}

                <p className="text-lg font-medium leading-6 text-gray-800  w-44">
                  {item.product_name}
                </p>

                <div>
                  <p className="space-x-2">
                    <span className="text-lg font-semibold">
                      ₹{item.sale_price_gst}
                    </span>
                    <span className="text-sm line-through text-gray-500">
                      ₹{item.product_price}
                    </span>
                    <span className="text-sm text-red-700">
                      {" "}
                      {/* {(
                        (100 * (item.product_price - item.sale_price_gst)) /
                        item.product_price
                      ).toFixed()} */}
                      {calculatPercentage(item.product_price,item.sale_price_gst)}
                      %off
                    </span>
                  </p>
                </div>
              </div>
            </div>
          ))}
        </OwlCarousel>
      </div>
    </div>
  );
};

export default DealOfTheday;
