import React, { useState, useEffect } from "react";
import { HiSearch } from "react-icons/hi";
import { Link } from "react-router-dom";
import Img from "../../assets/logo.png";
import User from "./User";
import Sidbar from "./Sidbar";
import { useNavigate } from 'react-router-dom'
import { productSearch } from "../../redux/productAction"
import { useDispatch } from 'react-redux'
import { AiOutlineSearch } from "react-icons/ai"

import "../../App.css"
import { AiOutlineCloseCircle } from "react-icons/ai"
import NotificationDropdown from "../NotificationDropdown";
import NotificationLink from "../notification/NotificationLink";

function NavBar() {
  const navigat = useNavigate()

  const [token, settoken] = useState()

  useEffect(() => {
    if (!localStorage.getItem('test_token')) {
      settoken(0)
    } else {
      settoken(1)
    }

  }, [])


  const [nav, setNav] = useState(false);

  //const [show, setShow] = useState(false);
  // const [searchKye, setsearchKye] = useState("")

  const dispatch = useDispatch()



  const handleSearch = (e) => {
    navigat('/products');
    dispatch(productSearch(e.target.value));
  }



  // const handleSearchMobile = (e) => {
  //   e.preventDefault();
  //   // setsearchKye(e.target.value)
  //   dispatch(productSearch(e.target.value))
  //   navigat('/products');

  // }

  // const handleSeller = () => {
  //   window.location.href = "https://fusionproject.tk/FTL190170/kaeinyonne/seller/login";

  // }

  return (
    <div

      className="flex justify-between items-center
    w-full h-20 text-white bg-gradient-to-r from-[#217CD1] to-[#164574] px-3 sticky top-0 z-50 shadow-md"
    >
      <div>
        <Link to="/">
          <img
            className="h-16 w-auto p-1 
                 sm:h-20 
                 md:h-20 
                 lg:h-20"
            src={Img}
            alt=""
          />
        </Link>
      </div>

      <form className="hidden items-center justify-center w-1/3  md:flex md:ml-0 lg:ml-12">
        <label htmlFor="simple-search" className="sr-only">
          Search
        </label>
        <div className="relative w-full">
          <div className="flex absolute text-[#88C645] inset-y-0 left-0 items-center pl-3 pointer-events-none ">
            <HiSearch size={25} />
          </div>
          <input
            type="text"
            onChange={(e) => handleSearch(e)}
            id="simple-search"
            className="bg-gray-50 border border-gray-300 text-gray-900 font-semibold text-sm rounded-md block w-full pl-10 p-2.5 "
            placeholder="Search"
            required
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                event.preventDefault();
                handleSearch(event);
              }
            }}
          />
        </div>
      </form>

      <ul className="md:flex items-center">

      <div className="flex space-x-2 justify-center">
  <Link
    to="/login"
    className={`inline-block px-2 py-1 text-xs  w-auto 
               sm:px-2 sm:py-2.5 sm:text-xs 
               md:px-8 md:py-3 md:text-sm 
               lg:px-12 lg:py-2.5 lg:text-base lg:w-30 
               ${token === 1 ? "hidden hid-tab" : "inline"} 
               text-white font-medium leading-tight uppercase rounded shadow-md 
               bg-[#88C645] hover:bg-[#F79946] hover:shadow-lg 
               focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg 
               transition duration-150 ease-in-out`}
  >
    Login
  </Link>
</div>



        <li

          className="mr-1 ml-2 h-text font-bold hover:text-[#F79946]  cursor-pointer"
        >
          <a target="_blank" rel="noreferrer" href="https://kaeinyonne.com/admin/seller/login" >Become a seller</a>
        </li>



      </ul>


      <ul className="xl:w-48 flex items-center justify-end">
        <div className="mr-2 cursor-pointer z-10 text-white md:hidden" onClick={() => setNav(!nav)}>

          <AiOutlineSearch size={25} />
        </div>
        <li className="p-1 mt-1">
          <Sidbar />
        </li>
        <li className="p-2 mt-1.5">
          <NotificationDropdown />
        </li>
        <li className="p-2 mt-1.5">
          <User />
        </li>
      </ul>
      {nav && (
        <div className="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <div className="search-bar relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4 relative ">
                  <div className="absolute right-2 top-2 text-black" onClick={() => setNav(!nav)}> <AiOutlineCloseCircle size={28} /> </div>
                  <div className="sm:flex sm:items-start mt-6">
                    <form className=" items-center justify-center ">
                      <label htmlFor="simple-search" className="sr-only">
                        Search
                      </label>
                      <div className="relative w-full">
                        <div className="flex absolute text-[#88C645]  inset-y-0 left-0 items-center pl-3 pointer-events-none ">
                          <HiSearch size={25} />
                        </div>
                        <input
                          type="text"
                          onChange={(e) => handleSearch(e)}
                          id="simple-search"
                          className="bg-gray-50 border border-gray-300 text-gray-900 font-semibold text-sm rounded-md block w-full pl-10 p-2.5 "
                          placeholder="Search"
                          required
                          onKeyDown={(event) => {
                            if (event.key === 'Enter') {
                              event.preventDefault();
                              handleSearch(event);
                            }
                          }}
                        />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


      )}

    </div>
  );
}

export default NavBar;
