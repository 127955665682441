import React, { useState, useEffect } from "react";
import { FaShoppingCart } from "react-icons/fa";
import { emptyCart, removeFromCart } from "../../redux/action";
// import { removeFromCart } from "../../redux/action";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import LoadingBar from "react-top-loading-bar";
import axios from "axios";
import { toast } from "react-custom-alert";
// import Img from "../../assets/logo2.png";
import { MdDelete } from "react-icons/md";
import { Link } from "react-router-dom";
import { product } from "../../redux/productAction";
import { addToCart } from "../../redux/action";
const Sidbar = () => {
  var result = useSelector((state) => state.cartData);

  console.log("uuuuuu", result);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(addToCart());
  }, [dispatch]);

  const [show, setShow] = useState(false);
  const [progress, setProgress] = useState(0);
  //const [quantity, setQuantity] = useState(1)

  let imgUrl = "https://kaeinyonne.com/admin/product_image/";

  const [totalAmount, settotalAmount] = useState();
  const [gstAmountprice, setgstAmountprice] = useState();

  // let amount =
  //   result.length &&
  //   result.map((item) => +item.price).reduce((prev, next) => prev + next);

  const amount = result.length
    ? result.reduce((prev, next) => {
      const price = parseFloat(next.price);
      const quantity = parseInt(next.quantity);
      return prev + price * quantity;
    }, 0)
    : 0;

  const [cartItemData, setcartItemData] = useState([]);

  useEffect(() => {
    const cartItem = async () => {
      setProgress(10);
      try {
        await axios(process.env.REACT_APP_API_CART_LIST, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${JSON.parse(
              localStorage.getItem("test_token")
            )}`,

            "Content-Type": "application/json",
          },
        })
          .then((res) => {
            setProgress(75);

            setcartItemData(res.data.cart_list);
            settotalAmount(res.data.total_cart_amt);
            setgstAmountprice(res.data.total_gst_amount);
            setProgress(100);
          })
          .catch((error) => {
            if (error.response) {
              setProgress(100);
            } else if (error.request) {
              toast.error(error.request);
              setProgress(100);
            } else {
              toast.error(error.message);
              setProgress(100);
            }
          });
      } catch (err) {
        toast.error("Something Wrong");
      }
    };

    cartItem();
  }, [show]);

  let productQty = [];
  productQty =
    cartItemData.length && cartItemData.map((item) => +item.quantity);

  let productId = [];
  productId =
    cartItemData.length && cartItemData.map((item) => +item.product_id);

  let productAmount = [];
  productAmount =
    cartItemData.length && cartItemData.map((item) => +item.price);

  let productSellerId = [];
  productSellerId =
    cartItemData.length && cartItemData.map((item) => +item.seller_id);

  let productColordata = [];
  productColordata =
    cartItemData.length && cartItemData.map((item) => item.color);

  let productSizedata = [];
  productSizedata =
    cartItemData.length && cartItemData.map((item) => item.size);

  let productTotelPriceWithGat = [];
  productTotelPriceWithGat =
    cartItemData.length &&
    cartItemData.map((item) => item.total_price_with_gst);

  // console.log("ggggtttss", productTotelPriceWithGat);

  const handleRemove = async (id) => {
    try {
      await axios(process.env.REACT_APP_API_CART_DELETE, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${JSON.parse(
            localStorage.getItem("test_token")
          )}`,

          "Content-Type": "application/json",
        },
        data: {
          cart_id: id,
        },
      })
        .then((res) => {
          setProgress(75);

          toast.success(res.data.message);
          dispatch(removeFromCart(id));

          setProgress(100);
          setShow(!show);
        })
        .catch((error) => {
          if (error.response) {
            toast.warning(error.response.data.message);

            setProgress(100);
          } else if (error.request) {
            toast.error(error.request);
            setProgress(100);
          } else {
            toast.error(error.message);
            setProgress(100);
          }
        });
    } catch (err) {
      toast.error("Something Wrong");
    }
  };

  const [checkout, setcheckout] = useState(false);

  const [userDeliveryAddress, setUserDeliveryAddress] = useState([]);

  useEffect(() => {
    const userDeliveryAddress = async () => {
      setProgress(10);
      try {
        await axios(process.env.REACT_APP_API_ADD_USER_DELIVERY_ADDRESS, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${JSON.parse(
              localStorage.getItem("test_token")
            )}`,

            "Content-Type": "application/json",
          },
        })
          .then((res) => {
            setProgress(75);

            setUserDeliveryAddress(res.data.address);

            setProgress(100);
          })
          .catch((error) => {
            if (error.response) {
              setProgress(100);
            } else if (error.request) {
              toast.error(error.request);
              setProgress(100);
            } else {
              toast.error(error.message);
              setProgress(100);
            }
          });
      } catch (err) {
        toast.error("Something Wrong");
      }
    };

    userDeliveryAddress();
  }, [checkout]);

  // let userNumber =
  //   userDeliveryAddress.length &&
  //   userDeliveryAddress.map((item) => +item.phone);

  let userid =
    userDeliveryAddress.length && userDeliveryAddress.map((item) => +item.id);

  const deliveryCharge = [];

  if (totalAmount <= 449) {
    // console.log(totalAmount, "total amount");
    // console.log("noProduct", result.length);
    for (let i = 0; i < result.length; i++) {
      if (i === 0) {
        deliveryCharge.push(49);
      } else {
        deliveryCharge.push(0);
      }
    }
  } else {
    // console.log(totalAmount, "total amount");

    for (var j = 0; j < result.length; j++) {
      deliveryCharge.push(0);
      // console.log("noProduct", result.length);
    }
  }

  // console.log("deliveryCharge", deliveryCharge);

  const [name, setName] = useState();
  const [number, setnumber] = useState();
  const [address, setAddress] = useState();
  const [city, setcity] = useState();
  const [pinCord, setpinCord] = useState();
  const [state, setstate] = useState();
  const [country, setcountry] = useState();
  const [type, settype] = useState("Home");
  const [paymentType, setpaymentType] = useState("online");

  const [deliveryId, setDeliveryId] = useState();

  const chakeOutPay = () => {
    setcheckout(!checkout);
    setShow(!show);
  };

  const [hasShownWarningForCod, setHasShownWarningForCod] = useState(false);
  const [hasShownWarningForWallet, setHasShownWarningForWallet] = useState(false);

  useEffect(() => {
    if (paymentType === 'cod' && !hasShownWarningForCod) {
      toast.warning('cod products can not be returned');
      setHasShownWarningForCod(true);
      setHasShownWarningForWallet(false); // Reset warning for wallet
    } else if (paymentType === 'wallet' && !hasShownWarningForWallet) {
      toast.warning('wallet products can not be returned');
      setHasShownWarningForWallet(true);
      setHasShownWarningForCod(false); // Reset warning for COD
    }
  }, [paymentType, hasShownWarningForCod, hasShownWarningForWallet]);

  function arrayString(arr) {
    return '[' + arr.join(', ') + ']';
  }

  const [dataInf, setDataInf] = useState()
  useEffect(() => {
    const getInf = async () => {
      try {
        const response = await axios("https://kaeinyonne.com/admin/api/check-purchase-premium-service-deliveryCharge", {
          method: "POST",
          headers: {
            Authorization: `Bearer ${JSON.parse(
              localStorage.getItem("test_token")
            )}`,

            "Content-Type": "application/json",
          },
        })
        // console.log("kkkkkrr", response.data)
        //const trackingUrl = response.data
        setDataInf(response.data.delivery_charge)

      } catch (error) {
        console.error('Error:', error.message);
        // toast.error('Something went wrong');
      }
    };
    getInf()
  }, [])

  // console.log("dataaa", dataInf)


  const handlePay = async () => {
    switch (paymentType) {
      case "cod":
        try {
          setProgress(75);
          axios(process.env.REACT_APP_API_ORDER, {
            method: "POST",
            headers: {
              Authorization: `Bearer ${JSON.parse(
                localStorage.getItem("test_token")
              )}`,

              "Content-Type": "application/json",
            },
            data: {
              product_id: JSON.stringify(productId),
              quantity: JSON.stringify(productQty),
              product_price: JSON.stringify(productAmount),
              delivery_address_id:
                JSON.stringify(+deliveryId.toString()) ||
                JSON.stringify(+userid[0].toString()),
              payment_mode: paymentType,
              //order_id: response.razorpay_order_id,
              payment_id: "",
              total_price: dataInf === 'true' ? (totalAmount <= 449 ? totalAmount + 49 : totalAmount) : totalAmount,
              seller_id: JSON.stringify(productSellerId),
              product_color: arrayString(productColordata),
              product_size: arrayString(productSizedata),
              delivery_charge: dataInf === 'true' ? (JSON.stringify(deliveryCharge)) : JSON.stringify([0]),
              total_price_with_gst: JSON.stringify(productTotelPriceWithGat),
            },
          })
            .then((res) => {
              setProgress(100);
              //console.log("lllll", dataInf === 'true' ? (JSON.stringify(deliveryCharge)) : JSON.stringify([0]))
              if (res.data.message === "success") {
                dispatch(emptyCart());
                toast.success(res.data.message);
                //toast.success('Payment successful! Cart has been emptied.');
              } else {
                toast.warning("Payment failed");
              }
            })
            .catch((error) => {
              if (error.response) {
                toast.warning(error.response.data.message);

                setProgress(100);
              } else if (error.request) {
                toast.error(error.request);
                setProgress(100);
              } else {
                toast.error(error.message);
                setProgress(100);
              }
            });
        } catch (err) {
          toast.error("Something Wrong");
        }

        break;

      case "online":
        try {
          setProgress(75);
          axios(process.env.REACT_APP_API_ORDER, {
            method: "POST",
            headers: {
              Authorization: `Bearer ${JSON.parse(
                localStorage.getItem("test_token")
              )}`,

              "Content-Type": "application/json",
            },
            data: {
              product_id: JSON.stringify(productId),
              quantity: JSON.stringify(productQty),
              product_price: JSON.stringify(productAmount),
              delivery_address_id:
                JSON.stringify(+deliveryId.toString()) ||
                JSON.stringify(+userid[0].toString()),
              payment_mode: paymentType,
              //order_id: response.razorpay_order_id,
              payment_id: "",
              total_price: dataInf === 'true' ? (totalAmount <= 449 ? totalAmount + 49 : totalAmount) : totalAmount,
              seller_id: JSON.stringify(productSellerId),
              product_color: arrayString(productColordata),
              product_size: arrayString(productSizedata),
              delivery_charge: dataInf === 'true' ? (JSON.stringify(deliveryCharge)) : JSON.stringify([0]),
              total_price_with_gst: JSON.stringify(productTotelPriceWithGat),
            },
          })
            .then((res) => {
              setProgress(100);

              const url = res.data.key;
              // console.log("url", url);
              // console.log("URL to Open:", url);
              window.open(url, "_blank");

              // if (res.data.message === 'success') {
              //   dispatch(emptyCart());
              //   toast.success(res.data.message);
              //   //toast.success('Payment successful! Cart has been emptied.');
              // } else {
              //   toast.warning('Payment failed');
              // }
            })
            .catch((error) => {
              if (error.response) {
                toast.warning(error.response.data.message);

                setProgress(100);
              } else if (error.request) {
                toast.error(error.request);
                setProgress(100);
              } else {
                toast.error(error.message);
                setProgress(100);
              }
            });
        } catch (err) {
          toast.error("Something Wrong");
        }

        break;
      case "wallet":
        try {
          setProgress(75);
          axios(process.env.REACT_APP_API_ORDER, {
            method: "POST",
            headers: {
              Authorization: `Bearer ${JSON.parse(
                localStorage.getItem("test_token")
              )}`,

              "Content-Type": "application/json",
            },
            data: {
              product_id: JSON.stringify(productId),
              quantity: JSON.stringify(productQty),
              product_price: JSON.stringify(productAmount),
              delivery_address_id:
                JSON.stringify(+deliveryId.toString()) ||
                JSON.stringify(+userid[0].toString()),
              payment_mode: paymentType,
              //order_id: response.razorpay_order_id,
              payment_id: "",
              total_price: dataInf === 'true' ? (totalAmount <= 449 ? totalAmount + 49 : totalAmount) : totalAmount,
              seller_id: JSON.stringify(productSellerId),
              product_color: arrayString(productColordata),
              product_size: arrayString(productSizedata),
              delivery_charge: dataInf === 'true' ? (JSON.stringify(deliveryCharge)) : JSON.stringify([0]),
              total_price_with_gst: JSON.stringify(productTotelPriceWithGat),
            },
          })
            .then((res) => {
              setProgress(100);

              if (res.data.message === "success") {
                dispatch(emptyCart());
                toast.success("Payment successful! Cart has been emptied.");
              } else {
                toast.warning("Payment failed");
              }
            })
            .catch((error) => {
              if (error.response) {
                toast.warning(error.response.data.message);

                setProgress(100);
              } else if (error.request) {
                toast.error(error.request);
                setProgress(100);
              } else {
                toast.error(error.message);
                setProgress(100);
              }
            });
        } catch (err) {
          toast.error("Something Wrong");
        }

        break;
      default:
      // code block
    }
    setcheckout(!checkout);
    //dispatch(emptyCart());
  };

  const [nameErr, setnameErr] = useState(false);
  const [phoneErr, setphoneErr] = useState(false);

  //userName_Vault
  const nameHandler = (e) => {
    let item = e.target.value;
    const isValidName = /^[a-zA-Z]+ [a-zA-Z]/;

    if (isValidName.test(item)) {
      setnameErr(false);
    } else {
      setnameErr(true);
    }
    setName(item);
  };

  //userPhone_Vault
  const phoneHandler = (e) => {
    let item = e.target.value;
    if (item.length < 10) {
      setphoneErr(true);
    } else {
      setphoneErr(false);
    }
    setnumber(item);
  };

  const addressHandler = (e) => {
    let item = e.target.value;
    setAddress(item);
  };

  const handleSaveBtn = async (e) => {
    setProgress(10);

    try {
      setProgress(75);
      e.preventDefault();
      await axios(process.env.REACT_APP_API_ADD_DELIVERY_ADDRESS, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${JSON.parse(
            localStorage.getItem("test_token")
          )}`,

          "Content-Type": "application/json",
        },
        data: {
          contact_person_name: name,
          address_type: type,
          address: address,
          city: city,
          pin_code: pinCord,
          phone: number,
          state: state,
          country: country,
        },
      })
        .then((res) => {
          setProgress(100);

          toast.success(res.data.message);

          //navigat('/userprofile');
          //window.location.reload(false);
          //window.location.href = "http://localhost:3000/userprofile"
        })
        .catch((error) => {
          if (error.response) {
            toast.warning(error.response.data.message);

            setProgress(100);
          } else if (error.request) {
            toast.error(error.request);
            setProgress(100);
          } else {
            toast.error(error.message);
            setProgress(100);
          }
        });
    } catch (err) {
      setProgress(100);
      toast.error("Something Wrong");
    }
  };

  const handleDelete = async (e) => {
    try {
      setProgress(75);
      await axios(process.env.REACT_APP_API_ADD_DELIVERY_ADDRESS_DELETE, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${JSON.parse(
            localStorage.getItem("test_token")
          )}`,

          "Content-Type": "application/json",
        },
        data: {
          delivery_address_id: e,
        },
      })
        .then((res) => {
          setProgress(100);

          toast.success(res.data.message);

          //navigat('/userprofile');
          //window.location.reload(false);
          //window.location.href = "http://localhost:3000/userprofile"
        })
        .catch((error) => {
          if (error.response) {
            toast.warning(error.response.data.message);

            setProgress(100);
          } else if (error.request) {
            toast.error(error.request);
            setProgress(100);
          } else {
            toast.error(error.message);
            setProgress(100);
          }
        });
    } catch (err) {
      setProgress(100);
      toast.error("Something Wrong");
    }
  };

  const handleDeliveryId = (e) => {
    setDeliveryId(e);
  };

  // if(productQty.length === 1 ){
  //   window.location.reload(false);
  // }

  const cartIncrease = async (productId, price, totalPriceWithGst) => {
    let prices = +price
    //console.log(prices, "ppppppppppppppppp>>>")
    let gst_Price = totalPriceWithGst - prices
    //console.log(productId, price, totalPriceWithGst, gst_Price, "iiiiiiiiiiii++++++++++++++++++");


    try {
      setProgress(75);
      await axios("https://kaeinyonne.com/admin/api/cart-increase", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${JSON.parse(
            localStorage.getItem("test_token")
          )}`,

          "Content-Type": "application/json",
        },
        data: {
          product_id: productId,
          price: price,
          gst_price: gst_Price,
          total_price_with_gst: totalPriceWithGst
        },
      })
        .then((res) => {

          // console.log("yyyyyyyyyyyyyyyy>>>>", res)

          toast.success(res.data.message);
          // setShow(!show)
          //navigat('/userprofile');
          window.location.reload(false);
          //window.location.href = "http://localhost:3000/userprofile"
        })
        .catch((error) => {
          if (error.response) {
            toast.warning(error.response.data.message);


          } else if (error.request) {
            toast.error(error.request);

          } else {
            toast.error(error.message);

          }
        });
    } catch (err) {
      setProgress(100);
      toast.error("Something Wrong");
    }



  };

  const cartDecrease = async (productId, price, totalPriceWithGst) => {
    let prices = +price
    //console.log(prices, "ppppppppppppppppp>>>")
    let gst_Price = totalPriceWithGst - prices
    //console.log(productId, price, totalPriceWithGst, gst_Price, "iiiiiiiiiiii-----------------");


    try {
      setProgress(75);
      await axios("https://kaeinyonne.com/admin/api/cart-decrease", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${JSON.parse(
            localStorage.getItem("test_token")
          )}`,

          "Content-Type": "application/json",
        },
        data: {
          product_id: productId,
          price: price,
          gst_price: gst_Price,
          total_price_with_gst: totalPriceWithGst
        },
      })
        .then((res) => {

          // console.log("yyyyyyyyyyyyyyyy>>>>", res)
          toast.success(res.data.message);
          // setShow(!show)
          //navigat('/userprofile');
          window.location.reload(false);
          
          //window.location.href = "http://localhost:3000/userprofile"
        })
        .catch((error) => {
          if (error.response) {
            toast.warning(error.response.data.message);


          } else if (error.request) {
            toast.error(error.request);

          } else {
            toast.error(error.message);

          }
        });
    } catch (err) {
      setProgress(100);
      toast.error("Something Wrong");
    }


  }


  return (
    <div>
      <LoadingBar
        color="#FF8000"
        progress={progress}
        height={2}
        onLoaderFinished={() => setProgress(0)}
      />
      <div className="relative inline-block text-left">
        <div>
          <button
            onClick={() => setShow(!show)}
            className={`ml-1 lg:ml-4 relative inline-block ${localStorage.getItem("test_token") === null
              ? "cursor-not-allowed"
              : "cursor-pointer"
              }`}
            disabled={localStorage.getItem("test_token") === null}
          >
            <div className="absolute -top-2 right-3 bg-[#88C645] text-xs font-bold px-1.5 py-0.5 rounded-full">
              {localStorage.getItem("test_token") === null ? 0 : result.length}
            </div>
            <div className=" hover:text-orange-400">
              <FaShoppingCart size={20} />
            </div>
          </button>
        </div>
      </div>
      {show && (
        <div
          className="relative z-20"
          aria-labelledby="slide-over-title"
          role="dialog"
          aria-modal="true"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                <div className="pointer-events-auto w-screen max-w-md">
                  <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                    <div className="flex-1 overflow-y-auto py-6 px-4 sm:px-6">
                      <div className="flex items-start justify-between">
                        <h2
                          className="text-lg font-medium text-gray-900"
                          id="slide-over-title"
                        >
                          Shopping cart
                        </h2>
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            onClick={() => setShow(!show)}
                            className="-m-2 p-2 text-gray-400 hover:text-gray-500"
                          >
                            <span className="sr-only">Close panel</span>

                            <svg
                              className="h-6 w-6"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth="1.5"
                              stroke="currentColor"
                              aria-hidden="true"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M6 18L18 6M6 6l12 12"
                              />
                            </svg>
                          </button>
                        </div>
                      </div>

                      <div className="mt-8">
                        {result.map((item, i) => (
                          <div key={item.id} className="flow-root">
                            <ul className="-my-6 divide-y mt-1 divide-gray-200">
                              <li className="flex py-6">
                                <div className="h-24 w-24 flex-shrink-0 overflow-hidden rounded-md border border-gray-200">
                                  <Link
                                    onClick={() =>
                                      dispatch(product(item.product_id))
                                    }
                                    to={`/product/${item.product_id}`}
                                  >
                                    <img
                                      src={`${imgUrl}${item.product_details.product_image}`}
                                      alt=""
                                      className="p-2"
                                    />
                                  </Link>
                                </div>

                                <div className="ml-4 flex flex-1 flex-col">
                                  <div>
                                    <div className="flex justify-between text-base font-medium text-gray-900">
                                      <h3>{item.name}</h3>
                                      <p className="ml-4">
                                        ₹{item.product_details.sale_price_gst}
                                      </p>
                                    </div>
                                    <p className="mt-1 text-sm text-gray-500">
                                      {item.tags}
                                    </p>
                                  </div>
                                  <div className="">
                                    <p className="cart-size-sec">
                                      {" "}
                                      Size <span> {item.size} </span>
                                    </p>
                                    <p className="cart-color-sec">
                                      {" "}
                                      Color{" "}
                                      <span
                                        className="spann"
                                        style={{
                                          backgroundColor: `${item.color}`,
                                        }}
                                      >
                                        {" "}
                                      </span>{" "}
                                    </p>
                                  </div>
                                  <div className="flex flex-1 items-end justify-between text-sm">
                                    {/* <p className="text-gray-500">
                                      Qty {item.quantity}
                                    </p> */}

                                    <p className="text-gray-500">
                                      GST {item.product_details.product_gst}%
                                    </p>

                                    <div className="group flex items-center justify-between rounded-md overflow-hidden flex-shrink-0 border h-6 md:h-6 border-gray-900 text-black">
                                      <button
                                        onClick={() => cartDecrease(item.product_details.id, item.product_details.sale_price, item.product_details.sale_price_gst)}

                                        // Add your onClick and disabled logic here
                                        className="flex items-center justify-center flex-shrink-0 h-full transition ease-in-out duration-300 focus:outline-none w-5 md:w-5 text-heading border-e border-gray-900 hover:text-white hover:bg-heading"
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          fill="none"
                                          viewBox="0 0 24 24"
                                          strokeWidth="1.5"
                                          stroke="currentColor"
                                          aria-hidden="true"
                                          className="h-3 w-3"
                                        >
                                          <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 12h-15" />
                                        </svg>
                                      </button>
                                      <span className="font-semibold flex items-center justify-center h-full transition-colors duration-250 ease-in-out cursor-default flex-shrink-0 text-base text-heading w-5 md:w-5 xl:w-12">
                                        {item.quantity}
                                      </span>
                                      <button
                                        onClick={() => cartIncrease(item.product_details.id, item.product_details.sale_price, item.product_details.sale_price_gst)}
                                        // Add your onClick and disabled logic here
                                        className="flex flex-wrap items-center justify-center h-full flex-shrink-0 transition ease-in-out duration-300 focus:outline-none w-5 md:w-5 text-heading border-s border-gray-300 hover:text-white hover:bg-heading"
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          fill="none"
                                          viewBox="0 0 24 24"
                                          strokeWidth="1.5"
                                          stroke="currentColor"
                                          aria-hidden="true"
                                          className="h-3 w-3"
                                        >
                                          <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                                        </svg>
                                      </button>
                                    </div>

                                    <div className="flex">
                                      <button
                                        onClick={(e) => handleRemove(item.id)}
                                        className="font-medium text-green-600 hover:text-green-700"
                                      >
                                        Remove
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                        ))}
                      </div>
                    </div>

                    <div className="border-t border-gray-200 py-1 px-1 sm:px-6">
                      <div className="flex flex-col px-4 py-6 md:p-6 xl:p-8 w-full bg-gray-50 space-y-6   ">
                        {/* <h3 className="text-xl font-semibold leading-5 text-gray-800">
                          Summary
                        </h3> */}
                        <div className="flex justify-center items-center w-full space-y-4 flex-col border-gray-200 border-b pb-4">
                          <div className="flex justify-between  w-full">
                            <p className="text-base leading-4 text-gray-800">
                              Amount
                            </p>
                            <p className="text-base leading-4 text-gray-600">
                              ₹{amount.toFixed(2)}
                            </p>
                          </div>

                          {/* <div className="flex justify-between items-center w-full">
                            <p className="text-base leading-4 text-gray-800">
                              Discount{" "}
                              <span className="bg-gray-200 p-1 text-xs font-medium leading-3  text-gray-800">
                                STUDENT
                              </span>
                            </p>
                            <p className="text-base leading-4 text-gray-600">
                              -₹{amount / totalPrice} ({totalPrice}%)
                            </p>
                          </div> */}
                          <div className="flex justify-between items-center w-full">
                            {result[0] && result[0].product_details ? (
                              <>
                                <p className="text-base leading-4 text-gray-800">
                                  GST
                                </p>{" "}
                                {/*({result[0].product_details.product_gst}%)*/}
                                <p className="text-base leading-4 text-gray-600">
                                  ₹{gstAmountprice.toFixed(2)}
                                </p>
                              </>
                            ) : (
                              ""
                            )}
                          </div>

                          {totalAmount === 0 ? null : (totalAmount <= 449 && dataInf === "false") ? null : (totalAmount <= 449 && dataInf === "true") ? (
                            <div className="flex justify-between items-center w-full">
                              <p className="text-base leading-4 text-gray-800">
                                Delivery Charges
                              </p>

                              <p className="text-base leading-4 text-gray-600">
                                ₹49.00

                              </p>
                            </div>
                          ) : totalAmount > 449 ? null : <div className="flex justify-between items-center w-full">
                            <p className="text-base leading-4 text-gray-800">
                              Delivery Charges
                            </p>

                            <p className="text-base leading-4 text-gray-600">
                              ₹49.00
                            </p>
                          </div>
                          }
                        </div>
                        <div className="flex justify-between items-center w-full">
                          <p className="text-base font-semibold leading-4 text-gray-800">
                            Total Amount With Gst
                          </p>
                          <p className="text-base font-semibold leading-4 text-gray-600">
                            ₹
                            {result.length === 0
                              ? 0
                              : totalAmount === 0
                                ? null
                                : (totalAmount <= 449 && dataInf === "false")
                                  ? (totalAmount).toFixed(2)
                                  : totalAmount > 449
                                    ? totalAmount.toFixed(2)
                                    : (totalAmount <= 449 && dataInf === "true")
                                      ? (totalAmount + 49).toFixed(2) : (totalAmount).toFixed(2)
                            }
                          </p>
                        </div>
                      </div>
                      {/* <p className="mt-0.5 text-sm text-gray-500">
                        Shipping and taxes calculated at checkout.
                      </p> */}
                      <div className="mt-2 flex justify-center text-center text-sm text-gray-500">
                        <button
                          disabled={result.length === 0 ? true : false}
                          onClick={() => chakeOutPay()}
                          className={`flex-medium items-center  justify-center rounded-md border border-transparent ${result.length === 0
                            ? "bg-gray-500 cursor-not-allowed"
                            : "bg-green-500 hover:bg-green-700 cursor-pointer"
                            }  px-32 py-1 text-base font-medium text-white shadow-sm `}
                        >
                          Checkout
                        </button>
                      </div>
                      <div className="mt-2 flex justify-center text-center text-sm text-gray-500">
                        {/* <button
                          onClick={() => dispatch(emptyCart())}
                          className="font-medium text-green-600 hover:text-green-700"
                        >
                          Empty Cart
                        </button> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {checkout && (
        <div
          className="relative z-10"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <div className="bg-white px-6 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div className="">
                    {userDeliveryAddress.length === 0 ? (
                      <div className="mx-auto max-w-lg px-4 lg:px-8">
                        <form className="grid grid-cols-6 gap-4">
                          <div className="col-span-3">
                            <label className="block text-xs font-medium text-gray-700 dark:text-gray-900">
                              Full Name
                            </label>
                            <input
                              onChange={nameHandler}
                              type="text"
                              value={name}
                              placeholder="Full Name"
                              className="mt-1  flex h-10 w-full rounded-md border border-blue-500 bg-transparent py-2 px-3 text-sm text-gray-900 focus:outline-none focus:ring-1  focus:ring-offset-1  focus:ring-offset-blue-400"
                            />
                            {nameErr ? (
                              <label className=" text-red-500 ml-3">
                                Enter a valid name
                              </label>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="col-span-3">
                            <label className="block text-xs font-medium text-gray-700 dark:text-gray-900">
                              Phone
                            </label>
                            <input
                              onChange={phoneHandler}
                              type="number"
                              id="number"
                              value={number}
                              placeholder="Phone Number"
                              className="mt-1  flex h-10 w-full rounded-md border border-blue-500 bg-transparent py-2 px-3 text-sm text-gray-900 focus:outline-none focus:ring-1  focus:ring-offset-1  focus:ring-offset-blue-400 "
                            />
                            {phoneErr ? (
                              <label className=" text-red-500 ml-3">
                                Enter a valid name
                              </label>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="col-span-6">
                            <label className="block text-xs font-medium text-gray-700 dark:text-gray-900">
                              Address
                            </label>
                            <input
                              onChange={addressHandler}
                              type="text"
                              id="add"
                              value={address}
                              placeholder="Address"
                              className="mt-1  flex h-10 w-full rounded-md border border-blue-500 bg-transparent py-2 px-3 text-sm text-gray-900 focus:outline-none focus:ring-1  focus:ring-offset-1  focus:ring-offset-blue-400"
                            />
                          </div>

                          <fieldset className="col-span-6">
                            <div className="mt-1 space-y-2 rounded-md shadow-sm">
                              <div className="flex space-x-2">
                                <div className="flex-1">
                                  <label htmlFor="City" className="sr-only">
                                    City
                                  </label>
                                  <input
                                    type="text"
                                    onChange={(e) => setcity(e.target.value)}
                                    placeholder="City"
                                    value={city}
                                    className="mt-1  flex h-10 w-full rounded-md border border-blue-500 bg-transparent py-2 px-3 text-sm text-gray-900 focus:outline-none focus:ring-1  focus:ring-offset-1  focus:ring-offset-blue-400 "
                                  />
                                </div>
                                <div className="flex-1">
                                  <label htmlFor="PinCord" className="sr-only">
                                    PinCord
                                  </label>
                                  <input
                                    type="text"
                                    onChange={(e) => setpinCord(e.target.value)}
                                    placeholder="Pin Code"
                                    value={pinCord}
                                    className="mt-1  flex h-10 w-full rounded-md border border-blue-500 bg-transparent py-2 px-3 text-sm text-gray-900 focus:outline-none focus:ring-1  focus:ring-offset-1  focus:ring-offset-blue-400 "
                                  />
                                </div>
                              </div>
                            </div>
                          </fieldset>
                          <fieldset className="col-span-6">
                            <div className="mt-1 space-y-2 rounded-md shadow-sm">
                              <div className="flex space-x-2">
                                <div className="flex-1">
                                  <label htmlFor="Ctate" className="sr-only">
                                    State
                                  </label>
                                  <input
                                    type="text"
                                    onChange={(e) => setstate(e.target.value.toUpperCase())}
                                    placeholder="State"
                                    value={state}
                                    className="mt-1  flex h-10 w-full rounded-md border border-blue-500 bg-transparent py-2 px-3 text-sm text-gray-900 focus:outline-none focus:ring-1  focus:ring-offset-1  focus:ring-offset-blue-400 "
                                  />
                                </div>
                                <div className="flex-1">
                                  <label htmlFor="Country" className="sr-only">
                                    Country
                                  </label>
                                  <input
                                    type="text"
                                    onChange={(e) => setcountry(e.target.value)}
                                    placeholder="Country"
                                    value={country}
                                    className="mt-1  flex h-10 w-full rounded-md border border-blue-500 bg-transparent py-2 px-3 text-sm text-gray-900 focus:outline-none focus:ring-1  focus:ring-offset-1  focus:ring-offset-blue-400"
                                  />
                                </div>
                              </div>
                            </div>
                          </fieldset>
                          <fieldset className="col-span-6">
                            <legend className="block text-sm font-medium text-gray-700">
                              Address Type
                            </legend>
                            <div className="mt-1 space-y-2 rounded-md shadow-sm">
                              <div>
                                <select
                                  id="Country"
                                  onChange={(e) => settype(e.target.value)}
                                  className="mt-1  flex h-10 w-full rounded-md border border-blue-500 bg-transparent py-2 px-3 text-sm text-gray-900 focus:outline-none focus:ring-1  focus:ring-offset-1  focus:ring-offset-blue-400"
                                >
                                  <option>Home</option>
                                  <option>Office</option>
                                  {/* <option>Scotland</option>
                                <option>France</option>
                                <option>Belgium</option>
                                <option>Japan</option> */}
                                </select>
                              </div>
                            </div>

                            <button
                              disabled={
                                !name ||
                                !type ||
                                !address ||
                                !city ||
                                !pinCord ||
                                !number ||
                                !state ||
                                !country
                              }
                              onClick={handleSaveBtn}
                              className={`rounded-md ${!name ||
                                !type ||
                                !address ||
                                !city ||
                                !pinCord ||
                                !number ||
                                !state ||
                                !country
                                ? "cursor-no-drop bg-gray-500 hover:bg-gray-700"
                                : "cursor-pointer bg-green-500 hover:bg-green-700"
                                } px-3.5 py-1.5 mt-3 w-full text-base font-semibold leading-7 text-white `}
                            >
                              save
                            </button>
                          </fieldset>
                        </form>
                      </div>
                    ) : (
                      <>
                        {userDeliveryAddress.map((add) => (
                          <div
                            key={add.id}
                            className="flex mb-4 items-center pl-4 border border-gray-200 rounded dark:border-gray-700"
                          >
                            <div className="flex items-center h-20">
                              <input
                                id={add.id}
                                aria-describedby="helper-radio-text"
                                type="radio"
                                checked={deliveryId === add.id ? true : false}
                                //value={add.id}
                                onClick={(e) => handleDeliveryId(add.id)}
                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 border rounded-full"
                              />
                            </div>
                            <div className="ml-2 text-sm relative">
                              <label
                                htmlFor="helper-radio"
                                className=" text-lg font-bold text-gray-900 "
                              >
                                {add.address_type}
                              </label>
                              <p
                                id="helper-radio-text"
                                className="  text-base  font-medium text-gray-900 "
                              >
                                {add.address}, {add.city}, {add.state},{" "}
                                {add.pin_code}, {add.country}
                              </p>
                              <button
                                onClick={(e) => handleDelete(add.id)}
                                className=" text-black absolute   md:left-96  top-1 left-56"
                              >
                                <MdDelete
                                  size={20}
                                  className="text-red-500 hover:text-red-800"
                                />
                              </button>
                            </div>
                          </div>
                        ))}
                      </>
                    )}
                  </div>
                </div>

                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  <button
                    //disabled={userDeliveryAddress.length === 0 ? true : false}
                    onClick={handlePay}
                    type="button"
                    className="inline-flex my-2 md:my-0 w-full items-center  justify-center rounded-md bg-[#88C645] px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-[#4e7228] sm:ml-3 sm:w-auto"
                  >
                    Continue
                  </button>
                  <button
                    onClick={() => setcheckout(!checkout)}
                    type="button"
                    className="my-2 md:my-0 items-center inline-flex w-full justify-center rounded-md bg-white px-3 py-2   text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                  >
                    Cancel
                  </button>
                  <div className=" space-y-2 rounded-md shadow-sm md:mr-3 ">
                    <div>
                      <select
                        id="Country"
                        onChange={(e) => setpaymentType(e.target.value)}
                        className="mt-1  flex h-10 w-full rounded-md border  border-blue-500 bg-transparent py-2 px-3 text-sm text-gray-900 focus:outline-none focus:ring-1  focus:ring-offset-1  focus:ring-offset-blue-400"
                      >
                        <option>online</option>
                        <option>cod</option>
                        <option>wallet</option>
                        {/* <option>Scotland</option>
                                <option>France</option>
                                <option>Belgium</option>
                                <option>Japan</option> */}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Sidbar;