import React, { useEffect } from "react";
import aboutimg from "../assets/about.png"

const About = () => {

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
  }, [])


  return (
    <div className="2xl:container 2xl:mx-auto lg:py-16 lg:px-20 md:py-12 md:px-6 py-9 px-4 min-h-screen ">
      <div className="flex flex-col lg:flex-row justify-between gap-8">
        <div className="w-full lg:w-5/12 flex flex-col justify-center mt-12">
          <h1 className="text-3xl lg:text-4xl font-bold leading-9 text-gray-800 pb-4">
            About Us
          </h1>
          <p className=" font-normal text-base leading-6 text-gray-600 ">
            Kaeinyonne is an e-commerce company that specializes in providing customers with high-quality products through its online platform. The company offers a wide range of products, including electronics, fashion items, home decor, beauty products, and many others. Kaeinyonne's primary goal is to provide customers with a seamless shopping experience that is fast, secure, and convenient.

            One of the key features of Kaeinyonne is its easy-to-use website & App. The platform is designed to be user-friendly and intuitive, allowing customers to easily find the products they are looking for. The website & App is also optimized for speed, ensuring that customers can quickly navigate between pages and make purchases with minimal hassle.

            Kaeinyonne is primarily an online shopping site, meaning that it provides a platform for customers to purchase products from various brands and sellers. However, it is possible that Kaeinyonne also operates as a selling site, allowing individual sellers or businesses to list and sell their products through the platform.
          </p>
        </div>
        <div className="w-full lg:w-8/12 mt-16">
          <img
            className="w-full h-full rounded"
            src={aboutimg}
            alt="A group of People"
          />
        </div>
      </div>

      <div className="flex lg:flex-row flex-col justify-between gap-8 pt-12">
        <div className="w-full flex flex-col justify-center">
          {/* <h1 className="text-3xl lg:text-4xl font-bold leading-9 text-gray-800 pb-4">
            Our Story
          </h1> */}
          <p className="font-normal text-base leading-6 text-gray-600 ">
            If Kaeinyonne operates as a selling site, it would provide a marketplace for sellers to reach a wider audience and sell their products to customers who may not have otherwise discovered them. This can be beneficial for small businesses or individual sellers who may not have the resources to establish their own e-commerce websites.

            Kaeinyonne also offers a range of payment options, including credit cards and other popular payment methods, making it easy for customers to make purchases online.

            In addition to its commitment to quality, Kaeinyonne also prioritizes customer satisfaction. The company offers a range of customer support services, including email and phone support, to help customers with any issues or questions they may have. Kaeinyonne also offers a hassle-free returns policy, allowing customers to return products that they are not satisfied with.

            Overall, Kaeinyonne is a top-tier e-commerce company that offers customers a wide range of high-quality products, a user-friendly platform, and excellent customer support. With its commitment to quality and customer satisfaction, Kaeinyonne is a great choice for anyone looking to purchase products online.
          </p>
        </div>
        {/* <div className="w-full lg:w-8/12 lg:pt-8">
          <div className="grid md:grid-cols-4 sm:grid-cols-2 grid-cols-1 lg:gap-4 shadow-lg rounded-md">
            <div className="p-4 pb-6 flex justify-center flex-col items-center">
              <img
                className="md:block hidden"
                src="https://i.ibb.co/FYTKDG6/Rectangle-118-2.png"
                alt="Alexa featured Img"
              />
              <img
                className="md:hidden block"
                src="https://i.ibb.co/zHjXqg4/Rectangle-118.png"
                alt="Alexa featured Img"
              />
              <p className="font-medium text-xl leading-5 text-gray-800 mt-4">
                Alexa
              </p>
            </div>
            <div className="p-4 pb-6 flex justify-center flex-col items-center">
              <img
                className="md:block hidden"
                src="https://i.ibb.co/fGmxhVy/Rectangle-119.png"
                alt="Olivia featured Img"
              />
              <img
                className="md:hidden block"
                src="https://i.ibb.co/NrWKJ1M/Rectangle-119.png"
                alt="Olivia featured Img"
              />
              <p className="font-medium text-xl leading-5 text-gray-800 mt-4">
                Olivia
              </p>
            </div>
            <div className="p-4 pb-6 flex justify-center flex-col items-center">
              <img
                className="md:block hidden"
                src="https://i.ibb.co/Pc6XVVC/Rectangle-120.png"
                alt="Liam featued Img"
              />
              <img
                className="md:hidden block"
                src="https://i.ibb.co/C5MMBcs/Rectangle-120.png"
                alt="Liam featued Img"
              />
              <p className="font-medium text-xl leading-5 text-gray-800 mt-4">
                Liam
              </p>
            </div>
            <div className="p-4 pb-6 flex justify-center flex-col items-center">
              <img
                className="md:block hidden"
                src="https://i.ibb.co/7nSJPXQ/Rectangle-121.png"
                alt="Elijah featured img"
              />
              <img
                className="md:hidden block"
                src="https://i.ibb.co/ThZBWxH/Rectangle-121.png"
                alt="Elijah featured img"
              />
              <p className="font-medium text-xl leading-5 text-gray-800 mt-4">
                Elijah
              </p>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default About;
