import React, { useState, useEffect } from "react";
import { AiOutlineHome } from "react-icons/ai";
import { BsTelephone } from "react-icons/bs";
import { HiOutlineMail } from "react-icons/hi";
import axios from 'axios'
import { toast } from 'react-custom-alert';
//import {useNavigate} from 'react-router-dom'
import LoadingBar from 'react-top-loading-bar'
import backgroundImage  from '../assets/kaeinyonnebg.jpeg'

const Contactus = () => {

  const [progress, setProgress] = useState(0)

  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [number, setNumber] = useState();
  const [message, setMessage] = useState()

  const [data, setData] = useState([])

  useEffect(() => {

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth"
    });

    const contactData = async () => {
      setProgress(10)
      try {
        setProgress(75)
        await axios.get(process.env.REACT_APP_API_CONTACT_API,
        ).then((res) => {
          setProgress(100)
          setData(res.data.list)

          //navigat('/')
        }).catch((error) => {
          if (error.response) { // status code out of the range of 2xx
   
            //toast.warning(error.response.data.message)
            // console.log("Status :" + error.response.status);
            setProgress(100)
          } else if (error.request) { // The request was made but no response was received
         
            toast.error(error.request)
            setProgress(100)
          } else {// Error on setting up the request
            
            toast.error(error.message)
            setProgress(100)
          }
        })

      } catch (err) {
        toast.error('Something Wrong')
      }
    }


    contactData()
  }, [])


  const submitData = async (e) => {
    setProgress(10)
    try {
      e.preventDefault();
      setProgress(75)
      await axios.post(process.env.REACT_APP_API_CONTACTUS_API, {
        name: name,
        email: email,
        mobile: number,
        message: message
      }).then((res) => {
        setProgress(100)
    
        toast.success(res.data.message)
        //navigat('/')
      }).catch((error) => {
        if (error.response) { // status code out of the range of 2xx
      
          toast.warning(error.response.data.message)
     
          setProgress(100)
        } else if (error.request) { // The request was made but no response was received
        
          toast.error(error.request)
          setProgress(100)
        } else {// Error on setting up the request
       
          toast.error(error.message)
          setProgress(100)
        }
      })

    } catch (err) {
      toast.error('Something Wrong')
    }
  }





  return (
    <div>
      <LoadingBar
        color='#FF8000'
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
      />
      <section className="relative z-10 overflow-hidden bg-white py-20 lg:py-[120px] min-h-screen ">
        <div className="container mx-auto">
          <div className="-mx-4 flex flex-wrap lg:justify-between">
            <div className="w-full px-4 lg:w-1/2 xl:w-6/12">
              {data.map((item) =>
                <div key={item.id} className="mb-12 max-w-[570px] lg:mb-0">
                  <span className="text-3xl lg:text-4xl font-bold leading-9 text-gray-800 pb-4">
                    Contact Us
                  </span>
                  <h2 className="text-dark mb-6 text-[32px] font-bold uppercase sm:text-[40px] lg:text-[36px] xl:text-[40px]">
                    GET IN TOUCH WITH US
                  </h2>
                  <p className="text-body-color mb-9 text-base leading-relaxed">
                    If you have any queries/issues don't hesitate, immediately contact us for any support. we will solve your problem as soon as possible.
                  </p>
                  <div className="mb-8 flex w-full max-w-[370px]">
                    <div className="bg-primary text-primary mr-6 flex h-[60px] w-full max-w-[60px] items-center justify-center overflow-hidden rounded bg-opacity-5 sm:h-[70px] sm:max-w-[70px]">
                      <AiOutlineHome size={30} />
                    </div>
                    <div className="w-full">
                      <h4 className="text-dark mb-1 text-xl font-bold">
                        Our Location
                      </h4>
                      <p className="text-body-color text-base">
                        {item.address}
                      </p>
                    </div>
                  </div>
                  <div className="mb-8 flex w-full max-w-[370px]">
                    <div className="bg-primary text-primary mr-6 flex h-[60px] w-full max-w-[60px] items-center justify-center overflow-hidden rounded bg-opacity-5 sm:h-[70px] sm:max-w-[70px]">
                      <BsTelephone size={28} />
                    </div>
                    <div className="w-full">
                      <h4 className="text-dark mb-1 text-xl font-bold">
                        Phone Number
                      </h4>
                      <p className="text-body-color text-base">
                        {item.phone}
                      </p>
                    </div>
                  </div>
                  <div className="mb-8 flex w-full max-w-[370px]">
                    <div className="bg-primary text-primary mr-6 flex h-[60px] w-full max-w-[60px] items-center justify-center overflow-hidden rounded bg-opacity-5 sm:h-[70px] sm:max-w-[70px]">
                      <HiOutlineMail size={32} />
                    </div>
                    <div className="w-full">
                      <h4 className="text-dark mb-1 text-xl font-bold">
                        Email Address
                      </h4>
                      <p className="text-body-color text-base">
                        {item.email}
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="w-full px-4 lg:w-1/2 xl:w-5/12">
              <div className="relative rounded-lg bg-gray-100 p-8 shadow-lg sm:p-12" style={{
                backgroundImage: `url(${backgroundImage})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat',
              }}>
                <form
                  onSubmit={submitData}
                >
                  <div className="mb-6">
                    <input
                      type="text"
                      name="name"
                      onChange={(e) => setName(e.target.value)}
                      placeholder="Your Name"
                      className="text-body-color bg-white rounded border-gray-300 focus:border-blue-500 focus:ring-2 focus:ring-indigo-200 border-[f0f0f0] focus:border-primary w-full border py-3 px-[14px] text-base outline-none focus-visible:shadow-none"
                    />
                  </div>
                  <div className="mb-6">
                    <input
                      type="email"
                      name="email"
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="Your Email"
                      className="text-body-color bg-white rounded border-gray-300 focus:border-blue-500 focus:ring-2 focus:ring-indigo-200 border-[f0f0f0] focus:border-primary w-full border py-3 px-[14px] text-base outline-none focus-visible:shadow-none"
                    />
                  </div>
                  <div className="mb-6">
                    <input
                      type="number"
                      name="mobile"
                      onChange={(e) => setNumber(e.target.value)}
                      placeholder="Your Phone Number"
                      className="text-body-color bg-white rounded border-gray-300 focus:border-blue-500 focus:ring-2 focus:ring-indigo-200 border-[f0f0f0] focus:border-primary w-full border py-3 px-[14px] text-base outline-none focus-visible:shadow-none"
                    />
                  </div>
                  <div className="mb-6">
                    <textarea
                      rows="10"
                      name="message"
                      onChange={(e) => setMessage(e.target.value)}
                      placeholder="Your Message"
                      className="text-body-color bg-white rounded border-gray-300 focus:border-blue-500 focus:ring-2 focus:ring-indigo-200 border-[f0f0f0] focus:border-primary w-full border py-3 px-[14px] text-base outline-none focus-visible:shadow-none"
                    ></textarea>
                  </div>
                  <div>
                    <button
                      type="submit"
                      className="relative inline-flex items-center justify-center px-6 py-2 overflow-hidden font-mono font-medium tracking-tighter text-white bg-gray-800 rounded-lg group"
                    >
                      <span className="absolute w-0 h-0 transition-all duration-500 ease-out bg-green-600 rounded-full group-hover:w-56 group-hover:h-56"></span>
                      <span className="absolute inset-0 w-full h-full -mt-1 rounded-lg opacity-30 bg-gradient-to-b from-transparent via-transparent to-gray-700"></span>
                      <span className="relative text-xl">Send Message</span>
                    </button>
                  </div>
                </form>
                <div>
                  <span className="absolute -top-10 -right-9 z-[-1]"></span>
                  <span className="absolute -left-7 -bottom-7 z-[-1]"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Contactus;
