import axios from "axios";
import React, { useState, useEffect } from "react";
import { toast } from "react-custom-alert";
import LoadingBar from "react-top-loading-bar";
import { useNavigate } from 'react-router-dom'
import { productBySubcategory } from "../../redux/productAction"
import { useDispatch } from 'react-redux'

import "../../App.css"

function Subnavbar() {
  const navigat = useNavigate()
  //const [showMan, setShowMan] = useState(false);
  const [showWoan, setShowWoan] = useState(false);
  const [progress, setProgress] = useState(0);
  const [subCategory, setSubCategory] = useState([]);
  const [category, setCategory] = useState([]);
  const [baseUrl, setBaseUrl] = useState();

  const dispatch = useDispatch()

  useEffect(() => {
    setProgress(45);
    const categoryList = async () => {
      try {
        setProgress(75);
        await axios(process.env.REACT_APP_API_CATEGORY_LIST, {
          method: "get",
        })
          .then((res) => {

            setCategory(res.data.category_list);
            setProgress(100);
          })
          .catch((error) => {
            if (error.response) {

              toast.warning(error.response.data.message);

              setProgress(100);
              //setProgress(progress + 100)
            } else if (error.request) {

              toast.error(error.request);
              setProgress(100);
              //setProgress(progress + 100)
            } else {

              toast.error(error.message);
              setProgress(100);
              //setProgress(progress + 100)
            }
          });
      } catch (err) {
        toast.error("Something Wrong");
        setProgress(100);
      }
    };
    categoryList();
  }, []);

  const handelSubCategory = async (e) => {

    try {
      setProgress(75);
      await axios
        .post(process.env.REACT_APP_API_SUB_CATEGORY_LIST, {
          category_id: e.currentTarget.id,
        })
        .then((res) => {

          setShowWoan(!showWoan);
          setSubCategory(res.data.sub_category_list);
          setBaseUrl(res.data.baseUrl);
          setProgress(100);
        })
        .catch((error) => {
          if (error.response) {

            toast.warning(error.response.data.message);

            setProgress(100);
            //setProgress(progress + 100)
          } else if (error.request) {

            toast.error(error.request);
            setProgress(100);
            //setProgress(progress + 100)
          } else {

            toast.error(error.message);
            setProgress(100);
            //setProgress(progress + 100)
          }
        });
    } catch (err) {
      toast.error("Something Wrong");
      setProgress(100);
    }
  };

  const hendelSubCategoryId = (e) => {
    navigat('/products')
    dispatch(productBySubcategory(e.currentTarget.id))
  };

  return (
    <div>
      <LoadingBar
        color="#FF8000"
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
      />
      <header className="relative bg-white shadow-xl">
        <nav
          aria-label="Top"
          className="name"
        >
          <div className="flex md:h-16 lg:h-16 items-center lg:justify-center md:justify-start overflow-hidden">
            <div className=" lg:block lg:self-stretch ">
              <div className="flex h-full w-screen  overflow-x-auto overflow-y-hidden items-center  md:px-7">
                <div className="flex max-md:gap-x-8 ">
                  {category.map((category) => (
                    <div key={category.id} className=" w-auto">
                      <button
                        id={category.id}
                        onClick={handelSubCategory}
                        duration={500}
                        onMouseLeave={() => setShowWoan(false)}
                        type="button"
                        className="border-transparent text-gray-800 lg:px-4 sm:px-2 py-2 c-text text-center hover:text-[#217CD1] relative z-10 -mb-px flex items-center border-b-2 pt-px font-semibold sm:text-xs lg:text-lg transition-colors duration-200 ease-out w-full whitespace-nowrap"
                      >
                        {category.category_name}
                      </button>
                    </div>
                  ))}


                  {showWoan && (
                    <div className="absolute inset-x-0 top-full text-sm text-gray-500">
                      <div
                        className="absolute inset-0 top-1/2 bg-gray-100"
                      ></div>
                      <div
                        onMouseEnter={() => setShowWoan(true)}
                        duration={500}
                        className="relative bg-white  shadow-xl z-30"
                      >
                        <div className="mx-auto max-w-7xl px-8">
                          <div className="grid-cols-2 gap-y-10 gap-x-8 py-16 menu-sec">
                            <div className="col-start-2 mb-4 grid grid-cols-2 gap-x-8">
                              {subCategory.map((img, index) => (
                                <div
                                  key={img.id}
                                  className="group relative text-base sm:text-sm"
                                >
                                  {index === 0 ? (
                                    <div className="aspect-w-1 aspect-h-1 overflow-hidden   group-hover:opacity-75">
                                      <img
                                        src={`${baseUrl}${img.icon}`}
                                        alt="Models sitting back to back, wearing Basic Tee in black and bone."
                                        className="object-cover object-center rounded-lg"
                                      />
                                      {/* <h1   className="mt-3  font-medium text-xl text-gray-900 text-center">{img.category_name}</h1> */}
                                    </div>
                                  ) : null}
                                  {index === 1 ? (
                                    <div className="aspect-w-1 aspect-h-1 overflow-hidden   group-hover:opacity-75">
                                      <img
                                        src={`${baseUrl}${img.icon}`}
                                        alt="Models sitting back to back, wearing Basic Tee in black and bone."
                                        className="object-cover object-center rounded-md"
                                      />
                                      {/* <h1   className="mt-3  font-medium text-xl text-gray-900 text-center">{img.category_name}</h1> */}
                                    </div>
                                  ) : null}
                                </div>
                              ))}
                            </div>

                            <div className="row-start-1 grid grid-cols-3 gap-y-10 gap-x-8 text-base">
                              <div>
                                <p
                                  id="Clothing-heading"
                                  className="font-medium text-lg text-gray-900"
                                >
                                  Sub category
                                </p>
                                {subCategory.slice(0, 6).map((subCategory) => (
                                  <ul
                                    key={subCategory.id}
                                    className="mt-6 space-y-6 sm:mt-4 sm:space-y-4"
                                  >
                                    <li className="flex">
                                      <button
                                        id={subCategory.id}
                                        onClick={hendelSubCategoryId}
                                        className="hover:text-gray-800"
                                      >
                                        {subCategory.category_name}
                                      </button>
                                    </li>
                                  </ul>
                                ))}
                              </div>

                              <div>
                                {/* <p
                                  id="Accessories-heading"
                                  className="font-medium text-lg text-gray-900"
                                >
                                  Sub category
                                </p> */}
                                {subCategory.slice(6, 12).map((subCategory) => (
                                  <ul
                                    key={subCategory.id}
                                    className="mt-6 space-y-6 sm:mt-4 sm:space-y-4"
                                  >
                                    <li className="flex">
                                      <button
                                        id={subCategory.id}
                                        onClick={hendelSubCategoryId}
                                        className="hover:text-gray-800"
                                      >
                                        {subCategory.category_name}
                                      </button>
                                    </li>
                                  </ul>
                                ))}
                              </div>

                              <div>
                                {/* <p
                                  id="Brands-heading"
                                  className="font-medium text-lg text-gray-900 "
                                >
                                  Sub category
                                </p> */}
                                {subCategory.slice(12, 18).map((subCategory) => (
                                  <ul
                                    key={subCategory.id}
                                    className="mt-6 space-y-6 sm:mt-4 sm:space-y-4"
                                  >
                                    <li className="flex">
                                      <button
                                        id={subCategory.id}
                                        onClick={hendelSubCategoryId}
                                        className="hover:text-gray-800"
                                      >
                                        {subCategory.category_name}
                                      </button>
                                    </li>
                                  </ul>
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </nav>
      </header>
    </div>
  );
}

export default Subnavbar;
