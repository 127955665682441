export const Add_To_Cart = 'Add_To_Cart'
export const Remove_From_Cart = 'Remove_From_Cart'
export const Empty_Cart = 'Empty_Cart'
export const Set_Cart_list = 'Set_Cart_list'


//product 


export const Product_List = 'Product_List'
export const Set_Product_List = 'Set_Product_List'
export const Product_By_Subcategory = 'Product_By_Subcategory'
export const Product_By_Category = 'Product_By_Category'
export const Product_Search = 'Product_Search'
export const Product_ = 'Product_'
export const Set_Product_ = 'Set_Product_'


// filter

export const Filter = 'Filter'

