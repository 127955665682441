import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Img from "../../assets/logo2.png";
import axios from 'axios'
import { toast } from 'react-custom-alert';
//import {useNavigate} from 'react-router-dom'
import LoadingBar from 'react-top-loading-bar'
import { AiFillEye } from 'react-icons/ai'
import { AiFillEyeInvisible } from "react-icons/ai"
import Flag from "../../assets/flag.png"
import Imgsigup from "../../assets/signup.jpg";
import backgroundImage  from '../../assets/kaeinyonnebg.jpeg'
const LogIn = () => {

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
  }, [])


  // const navigat = useNavigate()
  const [number, setNumber] = useState();
  const [password, setPassword] = useState();
  const [progress, setProgress] = useState(0)

  const [changePassword, setChangePassword] = useState(true);
  const changeIcon = changePassword === true ? false : true;


  const submitData = async (e) => {
    setProgress(10)



    try {
      e.preventDefault();
      setProgress(75)
      await axios.post(process.env.REACT_APP_API_LOGIN, {
        phone: number,
        password: password,
      }).then((res) => {
        setProgress(100)
        localStorage.setItem("test_token", JSON.stringify(res.data.token));
    
        toast.success("login is successfully completed")
        //navigat('/')
        window.location.href = "https://kaeinyonne.com/";
      }).catch((error) => {
        if (error.response) { // status code out of the range of 2xx
        
          toast.warning(error.response.data.message)
         
          setProgress(100)
        } else if (error.request) { // The request was made but no response was received
         
          toast.error(error.request)
          setProgress(100)
        } else {// Error on setting up the request
       
          toast.error(error.message)
          setProgress(100)
        }
      })

    } catch (err) {
      toast.error('Something Wrong')
    }
  }
  return (
    <section className="h-full gradient-form bg-white md:h-screen min-h-screen">
      <LoadingBar
        color='#FF8000'
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
      />
      <div className="container py-2 px-3 h-full ">
        <div className="flex justify-center items-center flex-wrap h-full g-6 text-gray-800 ">
          <div className="xl:w-10/12 ">
            <div className="block bg-white shadow-lg rounded-lg ">
              <div className="lg:flex lg:flex-wrap g-0 ">
                <div className="lg:w-6/12 px-4 md:px-0" style={{
                 backgroundImage: `url(${backgroundImage})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat',
                }}>
                  <div className="md:p-12 md:mx-6">
                    <div className="text-center">
                      <img className="mx-auto w-24 " src={Img} alt="logo" />
                      <h4 className="text-xl font-semibold mt-1 mb-3 pb-1">
                        Kaeinyonne
                      </h4>
                    </div>
                    <form >
                      <p className="mb-4">Please login to your account</p>
                      <div className="relative mb-4 text-left logincontainer">
                        <label
                          htmlFor="full-name"
                          className="leading-7 text-sm text-sky-500 "
                        >
                          Mobile/Email
                        </label>
                        <input
                          type="text"
                          id="full-name"
                          name="full-name"
                          onChange={(e) => setNumber(e.target.value)}
                          className="w-full relative logininput bg-white rounded border border-gray-300 focus:text-sky-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                        />
                        <img src={Flag} alt="" className="loginimage rounded-sm" />
                      </div>
                      <div className="relative mb-4 text-left ">
                        <label
                          htmlFor="email"
                          className="leading-7 text-sm  text-sky-500"
                        >
                          Password

                        </label>
                        <input
                          type={changePassword ? "password" : "text"}
                          id="password"
                          name="password"
                          onChange={(e) => setPassword(e.target.value)}
                          className="w-full relative  bg-white rounded border border-gray-300 focus:text-sky-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"

                        />
                        <span onClick={() => {
                          setChangePassword(changeIcon);
                        }}
                          className="absolute right-3 top-10 cursor-pointer">
                          {changeIcon ? <AiFillEye size={20} /> : <AiFillEyeInvisible size={20} />}
                        </span>

                      </div>
                      <Link to='/forgotpassword' className="text-right cursor-pointer text-sky-500">
                        <p>Forgot Password?</p>
                      </Link>
                      <button
                        disabled={!number || !password}
                        onClick={submitData} className={`inline-block px-6 mt-2 py-2.5 text-white ${!number || !password ? " bg-gray-500" : " bg-gradient-to-r from-[#88C645]  to-[#86cc3c]"}   font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg transition duration-150 ease-in-out w-full mb-3`}>
                        LogIn
                      </button>
                      <div className="flex items-center justify-between mt-3 pb-6">
                        <p className="mb-0 mr-2">Don't have an account?</p>
                        <Link
                          to="/signup"
                          type="button"
                          className="inline-block px-6 py-2 border-2 border-[#88C645]  text-[#88C645] font-medium text-xs leading-tight uppercase rounded hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0 transition duration-150 ease-in-out"
                          data-mdb-ripple="true"
                          data-mdb-ripple-color="light"
                        >
                          Sign up
                        </Link>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="lg:w-6/12 flex items-center lg:rounded-r-lg rounded-b-lg lg:rounded-bl-none bg-white">

                  {/* <h4 className="text-xl font-semibold mb-6">
                      We are more than just a company
                    </h4>
                    <p className="text-sm">
                      The hightlighted portiion is written in Latin language which means"it is important to take care of the pain itself,and it will be followed by the growth of the patient,but at the same time it will happen that there is a lot of work and plan.For to come to the smallest details,no one should practice any kind of work unless he drives some benefits from it."It's Irrelevent.Remove all.
                    </p> */}
                  <img src={Imgsigup} alt="" />

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LogIn;
